import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Button, Dialog, DialogHeader, DialogBody, DialogFooter, Card, Typography, Input, CardBody } from "@material-tailwind/react";
import { useFormik } from "formik";
import { activeUser } from "../../../../../helpers/AppUserApi";
import { toast } from "react-hot-toast";
import { invokeMBWebhookUpdate } from "../../../../../helpers/WebhookApi";
import { useMediaQuery } from "react-responsive";
import { updateMtAccount } from "../../../../../helpers/MtApi";

const EditModalMt = ({ open, setOpen, mtDetails }) => {
  const [name, setName] = useState(null);
  const [userId, setUserId] = useState(null);
  console.log(mtDetails);
  const isMobileScreen = useMediaQuery({ query: "(max-width: 1045px)" });
  const isMobileScreenShorter = useMediaQuery({ query: "(max-width: 487px)" });
  const excessModalHeight = useMediaQuery({ query: "(min-height: 600px)" });

  if (open) {
    let toastBox = toast;
    const activeUserPromise = activeUser();
    activeUserPromise
      .then((data) => {
        if (data.type === "admin") {
        } else if (data.type === "user") {
          setUserId(data.userid);
        }
      })
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  }

  const formik = useFormik({
    initialValues: {
      mt_id: mtDetails?.id,
      subdomain: mtDetails?.subdomain,
      name: mtDetails?.name,
    },
    validate: false,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      console.log(values);
      let toastBox = toast;
      if (!values.subdomain || !values.name) {
        let toastBox = toast;
        toast.error("All fields are required to be filled!", {
          id: toastBox,
        });
      } else {
        let invokePromise = updateMtAccount(values);
        invokePromise.then(
          (data) => {
            toast.success("Account updated successfully!", {
              id: toastBox,
            });
            window.location.reload();
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        );
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue("name", mtDetails?.name);
    formik.setFieldValue("subdomain", mtDetails?.subdomain);
    formik.setFieldValue("mt_id", mtDetails?.id);
  }, [mtDetails]);

  const handleOpen = () => {
    setName(null);
    setOpen(!open);
  };

  const handleConfirmButton = () => {
    setOpen(!open);
    formik.handleSubmit();
  };

  return (
    <Fragment>
      <Dialog open={open} handler={handleOpen} size={isMobileScreen ? (isMobileScreenShorter ? "xxl" : "xl") : "md"} className={!excessModalHeight ? "overflow-y-scroll" : "overflow-y-visible"} style={{ maxHeight: "90vh" }}>
        <DialogHeader>MarianaTek Integration Details</DialogHeader>
        <DialogBody divider>
          <Card className="w-96 mx-auto justify-center loginCard">
            <form>
              <Typography color="gray" className="mx-auto text-center mt-4 text-lg">
                Account details are listed below
              </Typography>
              {open && (
                <CardBody className="flex flex-col gap-4">
                  <Input label="Account name" size="lg" {...formik.getFieldProps("name")} />
                  <Input label="Sub-domain" size="lg" {...formik.getFieldProps("subdomain")} />
                </CardBody>
              )}
            </form>
          </Card>
        </DialogBody>
        <DialogFooter>
          <Button variant="text" color="red" onClick={handleOpen} className="mr-1">
            <span>Cancel</span>
          </Button>
          <Button variant="gradient" color="green" onClick={handleConfirmButton}>
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </Fragment>
  );
};

export default EditModalMt;
