import { Button, Divider, Modal, Spin, Steps, message } from "antd";
import React, { useState } from "react";
import LinkAccountGhlConnection from "./gohighlevelConnection/LinkAccountGhlConnection";
import ManageCustomTags from "./globalComponents/ManageCustomTags";
import { addClassBookingWebhook } from "../../../../../helpers/GhlApi";
import { toast } from "react-hot-toast";
import LinkClassCalendarGhlConnection from "./gohighlevelConnection/LinkClassCalendarGhlConnection";
import LinkAccountGhlToMtConnection from "./marianatekConnection/LinkAccountGhlToMtConnection";
import LinkClassGhlToMtConnection from "./marianatekConnection/LinkClassGhlToMtConnection";
import { addGhlToMtClassBookingWebhook } from "../../../../../helpers/MtApi";

const AddGHLToMtClassConnection = ({ open, setOpen }) => {
  const [connectionData, setConnectionData] = useState({
    connectionName: "",
    ghl_id: "",
    mt_id: "",
    locationId: "",
    classCalendar: [],
  });
  const [isClientFieldPresent, setIsClientFieldPresent] = useState(null);

  const steps = [
    {
      title: "Select accounts",
      content: "First-content",
    },
    {
      title: "Link calendars",
      content: "Second-content",
    },
    {
      title: "Modify GoHighLevel tags",
      content: "Last-content",
    },
  ];

  const [current, setCurrent] = useState(0);
  const next = () => {
    if (current === 0) {
      if (connectionData.ghl_id !== "" && connectionData.ghl_id !== null && connectionData.mt_id !== "" && connectionData.mt_id !== null && connectionData.connectionName !== "") setCurrent(current + 1);
      else toast.error(`Fill all fields!`);
    } else setCurrent(current + 1);
  };
  const prev = () => {
    if (current - 1 === 0) {
      setConnectionData((prevData) => ({ ...prevData, classCalendar: [] }));
    }
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const handleSubmitConnectionButton = () => {
    let toastBox = toast;
    toast.loading("Loading...", {
      id: toastBox,
    });
    let invokePromise = addGhlToMtClassBookingWebhook(connectionData);
    invokePromise.then(
      (data) => {
        toast.success("Connection added successfully", {
          id: toastBox,
        });
        window.location.reload();
      },
      (msg) => {
        toast.error(`${msg}`, {
          id: toastBox,
        });
      }
    );
  };

  const verifyClassSelections = () => {
    let letSave = true;
    if (connectionData.classCalendar.length !== 0) {
      console.log(connectionData.classCalendar);
      for (let i = 0; i < connectionData.classCalendar.length; i++) {
        if (!connectionData.classCalendar[i].calendarId || connectionData.classCalendar[i].calendarId === "") {
          letSave = false;
          break;
        } else if (!connectionData.classCalendar[i].classTypeId || connectionData.classCalendar[i].classTypeId === "") {
          letSave = false;
          break;
        } else if (!letSave) {
          break;
        }
        for (let j = i + 1; j < connectionData.classCalendar.length; j++) {
          if (connectionData.classCalendar[i].calendarId === connectionData.classCalendar[j].calendarId && connectionData.classCalendar[i].classTypeId === connectionData.classCalendar[j].classTypeId) {
            letSave = false;
            break;
          }
        }
      }
    } else letSave = false;

    if (letSave) return true;
    else return false;
  };

  return (
    <div>
      <Modal title={"Add new connection"} className="max-w-5xl" width={"70vw"} centered open={open} onOk={() => setOpen(false)} onCancel={() => setOpen(false)} footer={null}>
        <Divider className="mb-2 mt-0" />

        {/* <Spin spinning={loading}>{type === "contact_creation" ? <Tabs className="m-4" activeKey={activeTab} onChange={handleTabChange} tabPosition={"left"} items={ContactTabData} size="middle" /> : type === "mindbody_webhook" ? <Tabs className="m-4" activeKey={activeTab} onChange={handleTabChange} tabPosition={"left"} items={MindbodyTabData} size="middle" /> : <Tabs className="m-4" activeKey={activeTab} onChange={handleTabChange} tabPosition={"left"} items={TabData} size="middle" />}</Spin> */}
        <Spin spinning={isClientFieldPresent === null && current === 2}>
          <div className="flex gap-4">
            <div className="max-w-max min-w-max mt-2">
              <Steps current={current} items={items} className="site-navigation-steps" direction="vertical" size="small" />
            </div>
            <Divider className="h-36 bg-blue-gray-100 mt-2" type="vertical" />
            <div className="w-full rounded-lg">
              {current === 0 ? (
                <>
                  <LinkAccountGhlToMtConnection data={connectionData} setData={(data) => setConnectionData(data)} />
                </>
              ) : current === 1 ? (
                <>
                  <LinkClassGhlToMtConnection connectionData={connectionData} setConnectionData={(data) => setConnectionData(data)} />
                </>
              ) : (
                current === 2 && (
                  <>
                    <ManageCustomTags data={connectionData} setData={(data) => setConnectionData(data)} webhook_type={"manage_class"} isClientFieldPresent={isClientFieldPresent} setIsClientFieldPresent={(bool) => setIsClientFieldPresent(bool)} />
                  </>
                )
              )}
            </div>
          </div>

          <div
            style={{
              marginTop: 20,
              marginBottom: 0,
            }}
            className="w-full mx-auto justify-end text-end mb-0"
          >
            {current > 0 && (
              <Button
                style={{
                  margin: "0 8px",
                }}
                onClick={() => prev()}
              >
                Previous
              </Button>
            )}
            {current < steps.length - 1 && (
              <>
                {current === 0 || verifyClassSelections() === true ? (
                  <Button type="default" className="border-light-green-600 text-light-green-600" onClick={() => next()}>
                    Next
                  </Button>
                ) : (
                  <Button
                    type="default"
                    className="border-red-600 text-red-600"
                    onClick={() => {
                      message.warning("Check your selections!");
                    }}
                  >
                    Next
                  </Button>
                )}
              </>
            )}

            {current === steps.length - 1 && (
              <Button
                type="default"
                disabled={!isClientFieldPresent}
                onClick={() => {
                  handleSubmitConnectionButton();
                }}
              >
                Done
              </Button>
            )}
          </div>
        </Spin>
      </Modal>
    </div>
  );
};

export default AddGHLToMtClassConnection;
