import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Button, Dialog, DialogHeader, DialogBody, DialogFooter, Card, Typography, Input, CardBody, Select, Option, IconButton, CardFooter, Switch, Spinner } from "@material-tailwind/react";
import { useFormik } from "formik";
import { activeUser } from "../../../../../../helpers/AppUserApi";
import { Toaster, toast } from "react-hot-toast";
import { getActivationCode, invokeMBWebhook } from "../../../../../../helpers/WebhookApi";
import { addGHLData, getAllCalendarDetails, getCustomFieldDetails } from "../../../../../../helpers/GhlApi";
import { useMediaQuery } from "react-responsive";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { motion } from "framer-motion";
import { MdDoneAll } from "react-icons/md";
import { Button as AntdButton, Space, Spin } from "antd";
import ghlLogo from "../../../../../../data/images/ghl_solo.png";
import { initiateAuth } from "../../../../../../helpers/OauthApi";
import { addMtAccount, getMtAvailLocations, verifyMtAccountAndGetLocation } from "../../../../../../helpers/MtApi";

const AddIntegrationModal = ({ open, setOpen }) => {
  const [userId, setUserId] = useState(null);
  const [selectValue, setSelectValue] = useState(null);
  const [calendarDetails, setCalendarDetails] = useState([]);
  const [fieldDetails, setFieldDetails] = useState([]);
  const [memberfieldDetails, setMemberFieldDetails] = useState([]);
  const [selectCalendar, setSelectCalendar] = useState(null);
  const [selectClientFieldID, setSelectClientFieldID] = useState(null);
  const [selectMemberFieldID, setSelectMemberFieldID] = useState(null);
  const [calendarFieldActive, setCalendarFieldActive] = useState(false);
  const [memberFieldActive, setMemberFieldActive] = useState(false);
  const [calendarFetch, setCalendarFetch] = useState(true);
  const [fieldFetch, setFieldFetch] = useState(true);
  const [activationCode, setActivationCode] = useState(null);
  const [mtAvailLocations, setMtAvailLocations] = useState(null);
  const [dataLoader, setDataLoader] = useState({
    mtLocations: false,
  });

  const [ghlPage, setGhlPage] = useState(1);
  const [mbPage, setMbPage] = useState(1);

  const isMobileScreen = useMediaQuery({ query: "(max-width: 1045px)" });
  const isMobileScreenShorter = useMediaQuery({ query: "(max-width: 487px)" });
  const excessModalHeight = useMediaQuery({ query: "(min-height: 800px)" });

  if (open) {
    let toastBox = toast;
    const activeUserPromise = activeUser();
    activeUserPromise
      .then((data) => {
        if (data.type === "admin") {
        } else if (data.type === "user") {
          setUserId(data.userid);
        }
      })
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  }

  const verifyMTaccount = () => {
    let toastBox = toast;
    if (mtAvailLocations) setMtAvailLocations(null);
    else {
      setDataLoader((prevData) => ({ ...prevData, mtLocations: true }));
      const getAvailAccountPromise = verifyMtAccountAndGetLocation(mtFormik.values.subdomain);
      getAvailAccountPromise
        .then(
          (data) => {
            console.log(data);
            if (data) setMtAvailLocations(data);
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        })
        .finally(() => {
          setDataLoader((prevData) => ({ ...prevData, mtLocations: false }));
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      siteId: "",
      name: "",
      email: "",
      password: "",
    },
    validate: false,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      let toastBox = toast;
      const servicesId = ["addclient"];
      const newValues = { ...values, servicesId, userId };
      toast.loading("Loading...", {
        id: toastBox,
      });
      let invokePromise = invokeMBWebhook(newValues);
      invokePromise.then(
        (data) => {
          toast.success("MindBody Integration Successful", {
            id: toastBox,
          });
          window.location.reload();
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      );
    },
  });

  const mtFormik = useFormik({
    initialValues: {
      subdomain: "",
      name: "",
      locationId: "",
    },
    validate: false,
    validateOnBlur: true, // Optionally enable validation on blur
    validateOnChange: true, // Optionally enable validation on change
    onSubmit: async (values) => {
      let toastBox = toast;
      if (!values.subdomain || !values.name || !values.locationId) {
        let toastBox = toast;
        toast.error("All fields are required to be filled!", {
          id: toastBox,
        });
      } else {
        let invokePromise = addMtAccount(values);
        invokePromise.then(
          (data) => {
            toast.success("Account integrated successfully!", {
              id: toastBox,
            });
            window.location.reload();
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        );
      }
    },
  });

  const ghlformik = useFormik({
    initialValues: {
      apikey: "",
      name: "",
      calendarId: "",
      calendartags: "",
      clientFieldId: "",
      membershiptags: "",
      membershipFieldId: "",
      clientTags: "",
      memberCancelTags: "",
    },
    validate: false,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      let toastBox = toast;
      values.calendarId = selectCalendar;
      let calendarName = null;
      if (selectCalendar !== "" && selectCalendar !== null) {
        calendarName = calendarDetails.filter((element) => element.id === selectCalendar)[0].name;
      }
      values.clientFieldId = selectClientFieldID;
      values.membershipFieldId = selectMemberFieldID;
      const newValues = { ...values, userId, calendarName };
      toast.loading("Loading...", {
        id: toastBox,
      });
      if (selectClientFieldID) {
        let invokePromise = addGHLData(newValues);
        invokePromise.then(
          (data) => {
            toast.success("GoHighLevel Integration Successful", {
              id: toastBox,
            });
            window.location.reload();
          },
          (msg) => {
            if (msg.includes("Duplicate")) {
              toast.error(`Account already exists`, {
                id: toastBox,
              });
            } else {
              toast.error(`${msg}`, {
                id: toastBox,
              });
            }
          }
        );
      } else {
        toast.error(`Enter Client Id`, {
          id: toastBox,
        });
      }
    },
  });

  const getCalendarFunc = () => {
    let toastBox = toast;
    const getCalendarDetailsPromise = getAllCalendarDetails({
      ghl_id: ghlformik.values.apikey,
    });
    getCalendarDetailsPromise
      .then(
        (data) => {
          if (data) setCalendarDetails(data);
          setCalendarFetch(false);
        },
        (msg) => {
          if (msg.includes("401")) {
            toast.error("Invalid Api-key entered", {
              id: toastBox,
            });
          } else {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  const getCustomFieldFunc = () => {
    let toastBox = toast;
    const getFieldDetailsPromise = getCustomFieldDetails({
      ghl_id: ghlformik.values.apikey,
    });
    getFieldDetailsPromise
      .then(
        (data) => {
          if (data) setFieldDetails(data);
          setFieldFetch(false);
        },
        (msg) => {
          if (msg.includes("401")) {
            toast.error("Invalid Api-key entered", {
              id: toastBox,
            });
          } else {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        }
      )
      .catch((err) => {
        console.log("error");
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  const handleOpen = () => {
    setActivationCode(null);
    setMbPage(1);
    setOpen(!open);
  };

  const handleConfirmButton = () => {
    if (selectValue === "ghl") {
      setOpen(!open);
      ghlformik.handleSubmit();
    } else if (selectValue === "mb" && mbPage === 2) {
      formik.handleSubmit();
    } else if (selectValue === "mb" && mbPage === 1) {
      handleActivationCodeFetch();
      setMbPage(2);
    } else if (selectValue === "mt") mtFormik.handleSubmit();
  };

  const handleSelectChange = (e) => {
    setActivationCode(null);
    setMbPage(1);
    setSelectValue(e);
  };

  const handleCalendarChange = (event) => {
    setSelectCalendar(event);
  };

  const handleClientFieldIDChange = (event) => {
    setSelectClientFieldID(event);
    setMemberFieldDetails(fieldDetails.filter((element) => element.id !== event));
  };

  const handleMemberFieldIDChange = (event) => {
    setSelectMemberFieldID(event);
  };

  const handleActivationCodeFetch = () => {
    let toastBox = toast;
    const apikey = formik.values.apikey;
    const siteid = formik.values.siteId;
    const values = {
      apikey: apikey,
      siteid: siteid,
    };
    const getActivationCodePromise = getActivationCode(values);
    getActivationCodePromise.then(
      (data) => {
        setActivationCode(data);
        toast.success("Activation code fetched", {
          id: toastBox,
        });
      },
      ({ msg }) => {
        toast.error(`${msg}`, {
          id: toastBox,
        });
      }
    );
  };

  const handleOauth = async () => {
    const redirectURL = await initiateAuth();
    window.location.href = redirectURL;
  };

  useEffect(() => {
    setSelectCalendar(null);
    setSelectClientFieldID(null);
    setSelectMemberFieldID(null);
    setCalendarDetails([]);
    setFieldDetails([]);
    setMemberFieldDetails([]);
    setCalendarFieldActive(false);
    setMemberFieldActive(false);
  }, [ghlformik.values.apikey]);

  useEffect(() => {
    if (!calendarFieldActive) {
      setSelectCalendar(null);
      setCalendarDetails([]);
      ghlformik.values.calendartags = "";
    }
  }, [calendarFieldActive, ghlformik.values]);

  useEffect(() => {
    if (!memberFieldActive) {
      setSelectMemberFieldID(null);
      ghlformik.values.membershiptags = "";
      ghlformik.values.memberCancelTags = "";
    }
  }, [memberFieldActive, ghlformik.values]);

  return (
    <Fragment>
      <Dialog open={open} handler={handleOpen} size={isMobileScreen ? (isMobileScreenShorter ? "xxl" : "xl") : "md"} className={!excessModalHeight ? "overflow-y-scroll" : "overflow-y-visible"} style={{ maxHeight: "90vh", paddingTop: "5px" }}>
        <Toaster position="top-center" reverseOrder={false}></Toaster>
        <DialogHeader>Add new account</DialogHeader>
        <DialogBody divider>
          <Select label="Select account type" value={selectValue} onChange={handleSelectChange}>
            <Option value="ghl">Go High Level</Option>
            <Option value="mb">MindBody</Option>
            <Option value="mt">MarianaTek</Option>
          </Select>
          {selectValue === "mt" && (
            <Card className="mt-5 w-3/4 md:w-96 mx-auto justify-center loginCard">
              <form>
                <Typography color="gray" className="mx-auto text-center mt-4 text-lg">
                  Fill the details below
                </Typography>
                {open && (
                  <CardBody className="flex flex-col gap-4">
                    <Input label="Set a nickname for your account" size="lg" {...mtFormik.getFieldProps("name")} />
                    <div className="flex w-full gap-4">
                      <Input label="Sub-domain" size="lg" {...mtFormik.getFieldProps("subdomain")} disabled={mtAvailLocations} />
                      <button type="button" className={`${mtFormik.values.subdomain ? (mtAvailLocations ? `bg-red-400` : `bg-green-500`) : `bg-gray-400`} px-5 w-32 rounded-lg text-white hover:shadow-lg font-medium text-sm`} onClick={verifyMTaccount}>
                        {dataLoader.mtLocations ? (
                          <>
                            <Spin spinning={dataLoader.mtLocations}></Spin>
                          </>
                        ) : mtAvailLocations ? (
                          "Remove"
                        ) : (
                          "Verify"
                        )}
                      </button>
                    </div>
                    {/* <Input label="locationId" size="lg" {...mtFormik.getFieldProps("locationId")} /> */}
                    {mtAvailLocations && (
                      <Select label="Select location" onChange={(e) => mtFormik.setFieldValue("locationId", e)}>
                        {mtAvailLocations.length !== 0 &&
                          mtAvailLocations.map((data) => {
                            return (
                              <Option key={data?.id} value={data?.id}>
                                {data?.attributes?.name}
                              </Option>
                            );
                          })}
                      </Select>
                    )}
                  </CardBody>
                )}
              </form>
            </Card>
          )}
          {selectValue === "mb" && mbPage === 1 && (
            <Card className="mt-5 w-3/4 md:w-96 mx-auto justify-center loginCard">
              <form>
                <Typography color="gray" className="mx-auto text-center mt-4 text-lg">
                  Fill the details below
                </Typography>
                {open && (
                  <CardBody className="flex flex-col gap-4">
                    <Input label="Set a nickname for your account" size="lg" {...formik.getFieldProps("name")} />
                    <Input label="Site Id" size="lg" {...formik.getFieldProps("siteId")} />
                    <Input label="Username/Email" size="lg" {...formik.getFieldProps("email")} />
                    <Input label="Password" size="lg" type="password" {...formik.getFieldProps("password")} />
                  </CardBody>
                )}
              </form>
            </Card>
          )}
          {selectValue === "mb" && mbPage === 2 && (
            <Card className="mt-5 w-3/4 md:w-96 mx-auto justify-center loginCard">
              <form>
                <Typography color="gray" className="mx-auto text-center mt-4 text-lg">
                  Please add activation key to your mindbody account
                </Typography>
                {open && (
                  <CardBody className="flex flex-col gap-4">
                    {activationCode ? (
                      <Input
                        label="Activation code (tap to copy)"
                        size="lg"
                        readOnly
                        value={activationCode}
                        className="hover:cursor-pointer"
                        onClick={(e) => {
                          let toastBox = toast;
                          toast.success("Copied", {
                            id: toastBox,
                          });
                          return navigator.clipboard.writeText(e.target.value);
                        }}
                      />
                    ) : (
                      <div className="flex place-items-center w-full m-auto justify-center">
                        <Spinner className="h-6 w-6" />
                      </div>
                    )}
                    <Typography color="amber" className="mx-auto text-center mt-2 text-md">
                      Follow these steps to add activation code:
                    </Typography>
                    <div className="mx-auto text-center">
                      <Typography color="red" className="mx-auto text-center mt-2 text-sm">
                        Step 1: go to
                      </Typography>
                      <a href="https://clients.mindbodyonline.com/appshell/shuttle/staff/managecredentials" className="text-purple-400" target="_blank" rel="noreferrer">
                        https://clients.mindbodyonline.com/launch
                      </a>
                    </div>

                    <Typography color="red" className="mx-auto text-center mt-1 text-sm">
                      Step 2: Search with your Site ID and log in
                    </Typography>
                    <Typography color="red" className="mx-auto text-center mt-1 text-sm">
                      Step 3: Go to Setting Tab
                    </Typography>
                    <Typography color="red" className="mx-auto text-center mt-1 text-sm">
                      Step 4: Under MINDBODY Add-ons, click on API Integrations
                    </Typography>
                    <Typography color="red" className="mx-auto text-center mt-1 text-sm">
                      Step 5: On the right-side, under "Have an Activation Code?" box, enter your code and submit.
                    </Typography>
                  </CardBody>
                )}
              </form>
            </Card>
          )}
          {selectValue === "ghl" && ghlPage === 1 && (
            <motion.div
              className="w-full mt-4 mx-auto flex place-items-center"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5 }}
              transition={{
                duration: 1.0,
                delay: 0.2,
                ease: [0, 0.71, 0.2, 1.01],
              }}
            >
              <Card className="w-96 mx-auto justify-center loginCard">
                {/* <form>
                  <Typography color="gray" className="mx-auto text-center mt-4 text-lg">
                    Fill all the details below
                  </Typography>
                  <CardBody className="flex flex-col gap-4">
                    <Input label="API Key" size="lg" {...ghlformik.getFieldProps("apikey")} />
                    <Input label="Account name" size="lg" {...ghlformik.getFieldProps("name")} />
                  </CardBody>
                  <div className="grid grid-cols-2">
                    <Typography color="gray" className="pt-4 mx-auto text-end pl-6 w-full text-xs flex">
                      Need help?
                      <Typography as="a" href="#signup" variant="small" className="ml-1 font-bold text-yellow-800 text-xs">
                        Contact support
                      </Typography>
                    </Typography>
                    <CardFooter className="pt-0 mx-auto text-end w-full">
                      {ghlformik.values.apikey !== "" && ghlformik.values.name !== "" ? (
                        <IconButton
                          variant="gradient"
                          size="lg"
                          className="rounded-full text-lg"
                          onClick={() => {
                            setGhlPage(ghlPage + 1);
                          }}
                        >
                          <AiOutlineArrowRight />
                        </IconButton>
                      ) : (
                        <IconButton
                          variant="gradient"
                          color="gray"
                          size="lg"
                          className="rounded-full text-lg"
                          onClick={() => {
                            const toastBox = toast;
                            toast("Please fill all details!", {
                              id: toastBox,
                              icon: "⚠️",
                            });
                          }}
                        >
                          <AiOutlineArrowRight />
                        </IconButton>
                      )}
                    </CardFooter>
                  </div>
                </form> */}
                <button className="text-white bg-blue-600 rounded-lg py-3 hover:bg-blue-500 transition ease-in duration-100" onClick={handleOauth}>
                  <div className="flex items-center w-full mx-auto justify-center gap-3">
                    <img src={ghlLogo} alt="ghl" width={30} />
                    <span className="text-lg">Connect GHL Account</span>
                  </div>
                </button>
              </Card>
            </motion.div>
          )}
          {selectValue === "ghl" && ghlPage === 2 && (
            <motion.div
              className="w-full mx-auto mt-4 flex place-items-center"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5 }}
              transition={{
                duration: 1.0,
                delay: 0.2,
                ease: [0, 0.71, 0.2, 1.01],
              }}
            >
              {fieldFetch && ghlPage === 2 && getCustomFieldFunc()}
              <Card className="w-96 mx-auto justify-center loginCard">
                <Typography color="gray" className="mx-auto text-center mt-4 text-lg">
                  Select your custom field for storing Client ID
                </Typography>
                <CardBody className="flex flex-col gap-4">
                  {fieldDetails.length !== 0 && (
                    <Select variant="outlined" label="Select custom field" onChange={handleClientFieldIDChange} value={selectClientFieldID}>
                      {fieldDetails.map((data) => {
                        return (
                          <Option key={data.id} value={data.id}>
                            {data.name}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                  <Input label="Custom tags (if any)" size="lg" {...ghlformik.getFieldProps("clientTags")} />
                </CardBody>
                <div className="flex flex-wrap">
                  <div className="pt-0 mx-auto text-end w-fit">
                    <IconButton
                      size="lg"
                      className="rounded-full text-lg bg-yellow-800"
                      onClick={() => {
                        setGhlPage(ghlPage - 1);
                        setFieldFetch(true);
                      }}
                    >
                      <AiOutlineArrowLeft />
                    </IconButton>
                  </div>

                  <Typography color="gray" className="pt-4 mx-auto text-center justify-center w-fit text-xs flex">
                    Need help?
                    <Typography as="a" href="#signup" variant="small" className="ml-1 font-bold text-yellow-800 text-xs">
                      Contact support
                    </Typography>
                  </Typography>
                  <CardFooter className="p-0 py-6 pt-0 mx-auto text-end w-fit">
                    {selectClientFieldID ? (
                      <IconButton
                        variant="gradient"
                        size="lg"
                        className="rounded-full text-lg"
                        onClick={() => {
                          setGhlPage(ghlPage + 1);
                        }}
                      >
                        <AiOutlineArrowRight />
                      </IconButton>
                    ) : (
                      <IconButton
                        variant="gradient"
                        color="gray"
                        size="lg"
                        className="rounded-full text-lg"
                        onClick={() => {
                          const toastBox = toast;
                          toast("Please fill all details!", {
                            id: toastBox,
                            icon: "⚠️",
                          });
                        }}
                      >
                        <AiOutlineArrowRight />
                      </IconButton>
                    )}
                  </CardFooter>
                </div>
              </Card>
            </motion.div>
          )}
          {selectValue === "ghl" && ghlPage === 3 && (
            <motion.div
              className="w-full mt-4 mx-auto flex place-items-center"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5 }}
              transition={{
                duration: 1.0,
                delay: 0.2,
                ease: [0, 0.71, 0.2, 1.01],
              }}
            >
              <Card className="w-96 mx-auto justify-center loginCard">
                <Typography color="gray" className="mx-auto text-center mt-4 text-lg">
                  Select additional features (if required)
                </Typography>
                <CardBody className="flex flex-col gap-4">
                  <div className="pl-4">
                    <Switch
                      id="calendar"
                      onChange={(e) => setCalendarFieldActive(e.target.checked)}
                      checked={calendarFieldActive}
                      containerProps={{
                        className: "-mt-10",
                      }}
                      label={
                        <div>
                          <Typography color="blue-gray" className="font-medium ml-2">
                            Add Calendar
                          </Typography>
                          <Typography variant="small" color="gray" className="font-normal ml-2">
                            By enabling it, mindbody will be able to send class booking data.
                          </Typography>
                        </div>
                      }
                    />
                  </div>
                  <div className="pl-4">
                    <Switch
                      id="membership"
                      onChange={(e) => setMemberFieldActive(e.target.checked)}
                      checked={memberFieldActive}
                      containerProps={{
                        className: "-mt-10",
                      }}
                      label={
                        <div>
                          <Typography color="blue-gray" className="font-medium ml-2">
                            Add Membership
                          </Typography>
                          <Typography variant="small" color="gray" className="font-normal ml-2">
                            By enabling it, mindbody will be able to send membership data.
                          </Typography>
                        </div>
                      }
                    />
                  </div>
                </CardBody>
                <div className="flex flex-wrap">
                  <div className="pt-0 mx-auto text-end w-fit">
                    <IconButton
                      size="lg"
                      className="rounded-full text-lg bg-yellow-800"
                      onClick={() => {
                        setGhlPage(ghlPage - 1);
                      }}
                    >
                      <AiOutlineArrowLeft />
                    </IconButton>
                  </div>

                  <Typography color="gray" className="pt-4 mx-auto text-center justify-center w-fit text-xs flex">
                    Need help?
                    <Typography as="a" href="#signup" variant="small" className="ml-1 font-bold text-yellow-800 text-xs">
                      Contact support
                    </Typography>
                  </Typography>
                  <CardFooter className="p-0 py-6 pt-0 mx-auto text-end w-fit">
                    <IconButton
                      variant="gradient"
                      size="lg"
                      color={!calendarFieldActive && !memberFieldActive ? "green" : "blue"}
                      className="rounded-full text-lg"
                      onClick={() => {
                        if (calendarFieldActive) setGhlPage(ghlPage + 1);
                        else if (memberFieldActive) setGhlPage(ghlPage + 2);
                        else ghlformik.handleSubmit();
                      }}
                    >
                      {!calendarFieldActive && !memberFieldActive ? <MdDoneAll /> : <AiOutlineArrowRight />}
                    </IconButton>
                  </CardFooter>
                </div>
              </Card>
            </motion.div>
          )}
          {selectValue === "ghl" && ghlPage === 4 && (
            <motion.div
              className="w-full mt-4 mx-auto flex place-items-center"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5 }}
              transition={{
                duration: 1.0,
                delay: 0.2,
                ease: [0, 0.71, 0.2, 1.01],
              }}
            >
              {calendarFetch && ghlPage === 4 && getCalendarFunc()}
              <Card className="w-96 mx-auto justify-center loginCard">
                <Typography color="gray" className="mx-auto text-center mt-4 text-lg">
                  Select calendar where class booking will be stored from mindbody
                </Typography>
                <CardBody className="flex flex-col gap-4">
                  {calendarDetails.length !== 0 && (
                    <Select variant="outlined" label="Select calendar" onChange={handleCalendarChange} value={selectCalendar}>
                      {calendarDetails.map((data) => {
                        return (
                          <Option key={data.id} value={data.id}>
                            {data.name}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                  <Input label="Custom tags (if any)" size="lg" {...ghlformik.getFieldProps("calendartags")} />
                </CardBody>
                <div className="flex flex-wrap">
                  <div className="pt-0 mx-auto text-end w-fit">
                    <IconButton
                      size="lg"
                      className="rounded-full text-lg bg-yellow-800"
                      onClick={() => {
                        setGhlPage(ghlPage - 1);
                        setCalendarFetch(true);
                      }}
                    >
                      <AiOutlineArrowLeft />
                    </IconButton>
                  </div>

                  <Typography color="gray" className="pt-4 mx-auto text-center justify-center w-fit text-xs flex">
                    Need help?
                    <Typography as="a" href="#signup" variant="small" className="ml-1 font-bold text-yellow-800 text-xs">
                      Contact support
                    </Typography>
                  </Typography>
                  <CardFooter className="p-0 py-6 pt-0 mx-auto text-end w-fit">
                    {selectCalendar ? (
                      <IconButton
                        variant="gradient"
                        size="lg"
                        color={memberFieldActive ? "blue" : "green"}
                        className="rounded-full text-lg"
                        onClick={() => {
                          if (memberFieldActive) setGhlPage(ghlPage + 1);
                          else ghlformik.handleSubmit();
                        }}
                      >
                        {memberFieldActive ? <AiOutlineArrowRight /> : <MdDoneAll />}
                      </IconButton>
                    ) : (
                      <IconButton
                        variant="gradient"
                        color="gray"
                        size="lg"
                        className="rounded-full text-lg"
                        onClick={() => {
                          const toastBox = toast;
                          toast("Please fill all details!", {
                            id: toastBox,
                            icon: "⚠️",
                          });
                        }}
                      >
                        <AiOutlineArrowRight />
                      </IconButton>
                    )}
                  </CardFooter>
                </div>
              </Card>
            </motion.div>
          )}
          {selectValue === "ghl" && ghlPage === 5 && (
            <motion.div
              className="w-full mx-auto mt-4 flex place-items-center"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5 }}
              transition={{
                duration: 1.0,
                delay: 0.2,
                ease: [0, 0.71, 0.2, 1.01],
              }}
            >
              <Card className="w-96 mx-auto justify-center loginCard">
                <Typography color="gray" className="mx-auto text-center mt-4 text-lg">
                  Select your custom field for storing membership name
                </Typography>
                <CardBody className="flex flex-col gap-4">
                  {memberfieldDetails.length !== 0 && (
                    <Select variant="outlined" label="Select custom field" onChange={handleMemberFieldIDChange} value={selectMemberFieldID}>
                      {memberfieldDetails.map((data) => {
                        return (
                          <Option key={data.id} value={data.id}>
                            {data.name}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                  <Input label="Membership add tags (if any)" size="lg" {...ghlformik.getFieldProps("membershiptags")} />
                  <Input label="Membership cancel tags (if any)" size="lg" {...ghlformik.getFieldProps("memberCancelTags")} />
                </CardBody>
                <div className="flex flex-wrap">
                  <div className="pt-0 mx-auto text-end w-fit">
                    <IconButton
                      size="lg"
                      className="rounded-full text-lg bg-yellow-800"
                      onClick={() => {
                        if (calendarFieldActive) setGhlPage(ghlPage - 1);
                        else if (memberFieldActive) setGhlPage(ghlPage - 2);
                      }}
                    >
                      <AiOutlineArrowLeft />
                    </IconButton>
                  </div>

                  <Typography color="gray" className="pt-4 mx-auto text-center justify-center w-fit text-xs flex">
                    Need help?
                    <Typography as="a" href="#signup" variant="small" className="ml-1 font-bold text-yellow-800 text-xs">
                      Contact support
                    </Typography>
                  </Typography>
                  <CardFooter className="p-0 py-6 pt-0 mx-auto text-end w-fit">
                    {selectMemberFieldID ? (
                      <IconButton variant="gradient" size="lg" color="green" className="rounded-full text-lg" onClick={ghlformik.handleSubmit}>
                        <MdDoneAll />
                      </IconButton>
                    ) : (
                      <IconButton
                        variant="gradient"
                        color="gray"
                        size="lg"
                        className="rounded-full text-lg"
                        onClick={() => {
                          const toastBox = toast;
                          toast("Please fill all details!", {
                            id: toastBox,
                            icon: "⚠️",
                          });
                        }}
                      >
                        <MdDoneAll />
                      </IconButton>
                    )}
                  </CardFooter>
                </div>
              </Card>
            </motion.div>
          )}
        </DialogBody>
        <DialogFooter>
          <Button variant="text" color="red" onClick={handleOpen} className="mr-1">
            <span>Cancel</span>
          </Button>
          {(selectValue === "mb" || selectValue === "mt") && (
            <Button
              variant="gradient"
              color="green"
              // onClick={handleConfirmButton}
              onClick={handleConfirmButton}
            >
              <span>Confirm</span>
            </Button>
          )}
        </DialogFooter>
      </Dialog>
    </Fragment>
  );
};

export default AddIntegrationModal;
