import { Button, Checkbox, Divider, Empty, Input, Popover, Select, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { EditFilled } from "@ant-design/icons";
import UpdateGHLCustomFields from "../../connectionManagement/subComponents/UpdateGHLCustomFields";
import { getAllAddedGHLCalendars, getAllGHLAccountsSpecificUser } from "../../../../../../helpers/GhlApi";
import { toast } from "react-hot-toast";
import { getGHLAvailAccounts } from "../../../../../../helpers/AppUserApi";
import { FaQuestionCircle } from "react-icons/fa";
import { getMtAvailLocations, updateMTWebhook } from "../../../../../../helpers/MtApi";

const signedInHintContent = (
  <div>
    <p>By enabling it, a separate tag mentioning last signedIn dateTime will be added. It will auto-update with the latest signedIn event.</p>
    <p>Example: last signed in at 10th dec 7am</p>
  </div>
);

const notSignedInHintContent = (
  <div>
    <p>By enabling it, a separate tag mentioning last notSignedIn dateTime will be added. It will auto-update with the latest notSignedIn event.</p>
    <p>Example: last not signed in at 10th dec 7am</p>
  </div>
);

const ManageMtCustomTags = ({ data, setData, webhook_type, setupDifficulty, isClientFieldPresent, setIsClientFieldPresent, triggerTagsSaveButton, setTriggerTagsSaveButton, reloadWebhookData }) => {
  const [openCustomFieldModal, setOpenCustomFieldModal] = useState(false);
  const [calendarAvailDetails, setCalendarAvailDetails] = useState([]);
  const [selectedCalendarId, setSelectedCalendarId] = useState(null);
  const [uniqueCalendarIds, setUniqueCalendarIds] = useState([]);
  const [ghlAvailAccounts, setGhlAvailAccounts] = useState([]);
  const [selectedGhlAccount, setSelectedGhlAccount] = useState(null);

  const handleSelectGhlAccount = (e) => {
    setSelectedGhlAccount(e);
  };

  useEffect(() => {
    setSelectedCalendarId(null);
  }, [selectedGhlAccount]);

  const getGHLContactAvailAccFunc = () => {
    let toastBox = toast;
    const getAvailAccountPromise = getGHLAvailAccounts();
    getAvailAccountPromise
      .then(
        (data) => {
          if (data) setGhlAvailAccounts(data);
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
    // .finally(() => {
    //   setDataLoader((prevData) => ({ ...prevData, ghlAccounts: true }));
    // });
  };

  // const getMtLocationAvailAccFunc = () => {
  //   let toastBox = toast;
  //   const getAvailAccountPromise = getMtAvailLocations(data?.mt_id);
  //   getAvailAccountPromise
  //     .then(
  //       (data) => {
  //         console.log(data);
  //         if (data) setMtLocations(data);
  //       },
  //       (msg) => {
  //         toast.error(`${msg}`, {
  //           id: toastBox,
  //         });
  //       }
  //     )
  //     .catch((err) => {
  //       toast.error(`${err}`, {
  //         id: toastBox,
  //       });
  //     });
  // };

  useEffect(() => {
    // getMtLocationAvailAccFunc();
    getGHLContactAvailAccFunc();
  }, []);

  useEffect(() => {
    if (selectedGhlAccount) {
      const getAllAddedGHLCalendarsFunc = () => {
        const toastBox = toast;
        const getAllAddedGHLCalendarsPromise = getAllAddedGHLCalendars({
          ghl_id: selectedGhlAccount,
        });
        getAllAddedGHLCalendarsPromise
          .then(
            (data) => {
              if (data) {
                setCalendarAvailDetails(data);
              }
            },
            (msg) => {
              toast.error(`${msg}`, {
                id: toastBox,
              });
            }
          )
          .catch((err) => {
            toast.error(`${err}`, {
              id: toastBox,
            });
          });
      };
      getAllAddedGHLCalendarsFunc();
    }
  }, [selectedGhlAccount]);

  useEffect(() => {
    if (calendarAvailDetails.length !== 0) {
      const uniqueData = {};

      data.classCalendar
        .filter((element) => element.ghl_id === selectedGhlAccount)
        ?.forEach((item) => {
          item?.children?.forEach((element) => {
            if (!uniqueData[element.calendarId]) {
              uniqueData[element.calendarId] = {
                id: element.calendarId,
                name: "unknown",
              };
            }
          });
        });
      console.log(data.classCalendar);
      console.log(data.classCalendar.filter((element) => element.ghl_id === selectedGhlAccount));
      calendarAvailDetails.forEach((detail) => {
        if (uniqueData[detail.id]) {
          uniqueData[detail.id].name = detail.name;
        }
      });

      setUniqueCalendarIds(Object.values(uniqueData));
    }
  }, [data.classCalendar, calendarAvailDetails, selectedGhlAccount]);

  const handleCalendarSelect = (calendarId) => {
    setSelectedCalendarId(calendarId);
  };

  const handleClassBookingTagChange = (event) => {
    const newTags = event.target.value;
    // setData((prevData) => ({
    //   ...prevData,
    //   classCalendar: prevData.classCalendar.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.children.map((item) => (item.calendarId === selectedCalendarId ? { ...item, classBookingTags: newTags } : item)),
    // }));

    setData((prevData) => ({
      ...prevData,
      classCalendar: prevData.classCalendar.map((classItem) =>
        classItem.ghl_id === selectedGhlAccount
          ? {
              ...classItem,
              children: classItem.children.map((child) => (child.calendarId === selectedCalendarId ? { ...child, classBookingTags: newTags } : child)),
            }
          : classItem
      ),
    }));
  };

  const handleSignedInTagChange = (event) => {
    const newTags = event.target.value;
    // setData((prevData) => ({
    //   ...prevData,
    //   classCalendar: prevData.classCalendar.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.children.map((item) => (item.calendarId === selectedCalendarId ? { ...item, classBookingTags: newTags } : item)),
    // }));

    setData((prevData) => ({
      ...prevData,
      classCalendar: prevData.classCalendar.map((classItem) =>
        classItem.ghl_id === selectedGhlAccount
          ? {
              ...classItem,
              children: classItem.children.map((child) => (child.calendarId === selectedCalendarId ? { ...child, classCheckedInTags: newTags } : child)),
            }
          : classItem
      ),
    }));
  };

  const handleNotSignedInTagChange = (event) => {
    const newTags = event.target.value;
    // setData((prevData) => ({
    //   ...prevData,
    //   classCalendar: prevData.classCalendar.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.children.map((item) => (item.calendarId === selectedCalendarId ? { ...item, classBookingTags: newTags } : item)),
    // }));

    setData((prevData) => ({
      ...prevData,
      classCalendar: prevData.classCalendar.map((classItem) =>
        classItem.ghl_id === selectedGhlAccount
          ? {
              ...classItem,
              children: classItem.children.map((child) => (child.calendarId === selectedCalendarId ? { ...child, classNoShowedTags: newTags } : child)),
            }
          : classItem
      ),
    }));
  };

  const handleSignedInDateTimeSelect = () => {
    const signedInDateActiveStatus = data.classCalendar.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.children?.filter((element) => element.calendarId === selectedCalendarId)[0]?.signedInDateActive;

    setData((prevData) => ({
      ...prevData,
      classCalendar: prevData.classCalendar.map((classItem) =>
        classItem.ghl_id === selectedGhlAccount
          ? {
              ...classItem,
              children: classItem.children.map((child) => (child.calendarId === selectedCalendarId ? { ...child, signedInDateActive: signedInDateActiveStatus === "true" ? "false" : "true" } : child)),
            }
          : classItem
      ),
    }));
  };

  const handleNotSignedInDateTimeSelect = () => {
    const notSignedInDateActiveStatus = data.classCalendar.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.children?.filter((element) => element.calendarId === selectedCalendarId)[0]?.notSignedInDateActive;

    setData((prevData) => ({
      ...prevData,
      classCalendar: prevData.classCalendar.map((classItem) =>
        classItem.ghl_id === selectedGhlAccount
          ? {
              ...classItem,
              children: classItem.children.map((child) => (child.calendarId === selectedCalendarId ? { ...child, notSignedInDateActive: notSignedInDateActiveStatus === "true" ? "false" : "true" } : child)),
            }
          : classItem
      ),
    }));
  };

  const fetchGhlAccountFunc = () => {
    let toastBox = toast;
    const getAllGHLAccountsPromise = getAllGHLAccountsSpecificUser();
    getAllGHLAccountsPromise
      .then(
        (fetchData) => {
          if (fetchData) {
            setIsClientFieldPresent(fetchData?.find((item) => item.clientFieldID === null) ? false : true);
          }
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };
  useEffect(() => {
    fetchGhlAccountFunc();
  }, [data]);

  const handleSaveButton = () => {
    setTimeout(() => {
      // if (JSON.stringify(defaultCustomTags) === JSON.stringify(customTagsData) && ) {
      // } else {
      let toastBox = toast;
      toast.loading(`updating...`, {
        id: toastBox,
      });
      const updateMindbodyCustomTagsPromise = updateMTWebhook(data);
      updateMindbodyCustomTagsPromise
        .then(
          (msg) => {
            toast.success("Updated Successfully!", {
              id: toastBox,
            });
            reloadWebhookData();
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        })
        .finally(() => {
          // setLoading(false);
        });
      setTriggerTagsSaveButton(false);
    }, 300);
  };

  return (
    <div>
      {webhook_type === "marianatek_webhook" && triggerTagsSaveButton && handleSaveButton()}
      <div className="w-full mx-auto justify-end text-end">
        <Space>
          {isClientFieldPresent !== null && !isClientFieldPresent && <span className="text-red-600">Client Id field required !</span>}
          <Button type="default" icon={<EditFilled />} className="bg-blue-gray-100" onClick={() => setOpenCustomFieldModal(true)}>
            Update custom fields
          </Button>
        </Space>
      </div>
      <Divider className="my-2" />
      {webhook_type === "marianatek_webhook" ? (
        <>
          {setupDifficulty && (
            <div className="mb-2">
              <div className="grid 2xl:grid-cols-2 items-center gap-2 grid-cols-1">
                <div>
                  <Typography.Text>GHL account: </Typography.Text>
                  <Select placeholder="Select an account" className="ml-2 lg:max-w-xs xl:w-52" onChange={handleSelectGhlAccount} value={selectedGhlAccount}>
                    {ghlAvailAccounts.length !== 0 &&
                      ghlAvailAccounts.map((data, index) => {
                        return (
                          <Select.Option key={index} value={data.id}>
                            {data.name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </div>
                {/* <div>
                  <Typography.Text>MT Location: </Typography.Text>
                  <Select placeholder="Select a location" className="ml-2 lg:max-w-xs xl:w-52" onChange={(e) => setSelectedMtLocation(e)} value={selectedMtLocation}>
                    {mtLocations.length !== 0 &&
                      mtLocations.map((data) => {
                        return (
                          <Select.Option key={data?.id} value={data?.id}>
                            {data?.attributes?.name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </div> */}
              </div>
            </div>
          )}
          {((setupDifficulty && selectedGhlAccount) || !setupDifficulty) && (
            <div>
              <Space direction="vertical">
                <Input
                  addonBefore="Client creation"
                  value={data.contactCreationEvent.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.contactCreationTags}
                  onChange={(e) =>
                    setData((prevData) => ({
                      ...prevData,
                      contactCreationEvent: prevData.contactCreationEvent.map((classItem) =>
                        classItem.ghl_id === selectedGhlAccount
                          ? {
                              ...classItem,
                              contactCreationTags: e.target.value,
                            }
                          : classItem
                      ),
                    }))
                  }
                  placeholder="tags (optional)"
                />
                <Input
                  addonBefore="Contract purchase"
                  value={data.membershipEvent.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.membershipAddTags}
                  onChange={(e) =>
                    setData((prevData) => ({
                      ...prevData,
                      membershipEvent: prevData.membershipEvent.map((classItem) =>
                        classItem.ghl_id === selectedGhlAccount
                          ? {
                              ...classItem,
                              membershipAddTags: e.target.value,
                            }
                          : classItem
                      ),
                    }))
                  }
                  placeholder="tags (optional)"
                />
                <Input
                  addonBefore="Contract cancellation"
                  value={data.membershipEvent.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.membershipCancelTags}
                  onChange={(e) =>
                    setData((prevData) => ({
                      ...prevData,
                      membershipEvent: prevData.membershipEvent.map((classItem) =>
                        classItem.ghl_id === selectedGhlAccount
                          ? {
                              ...classItem,
                              membershipCancelTags: e.target.value,
                            }
                          : classItem
                      ),
                    }))
                  }
                  placeholder="tags (optional)"
                />
              </Space>
              <Divider className="my-4 w-full" />
              <Select placeholder="Select a calendar" className="max-w-[12rem] w-full" value={selectedCalendarId} onChange={(e) => handleCalendarSelect(e)}>
                {uniqueCalendarIds.length !== 0 &&
                  uniqueCalendarIds.map((element) => {
                    return (
                      <Select.Option key={element.id} value={element.id}>
                        {element.name}
                      </Select.Option>
                    );
                  })}
              </Select>
              {selectedCalendarId && (
                <div>
                  <Space direction="vertical" className="mt-2">
                    {data.classCalendar.filter((element) => element.ghl_id === selectedGhlAccount)?.length !== 0 && data.classCalendar.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.children?.filter((element) => element.calendarId === selectedCalendarId)?.length !== 0 && (
                      <Input
                        addonBefore="Class booking"
                        value={data.classCalendar.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.children?.filter((element) => element.calendarId === selectedCalendarId)[0]?.classBookingTags}
                        onChange={(e) => handleClassBookingTagChange(e)}
                        placeholder="tags (optional)"
                      />
                    )}
                    {data.classCalendar.filter((element) => element.ghl_id === selectedGhlAccount)?.length !== 0 && data.classCalendar.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.children?.filter((element) => element.calendarId === selectedCalendarId)?.length !== 0 && (
                      <div className="flex flex-wrap w-full gap-3">
                        <Input
                          addonBefore="Class checkedIn"
                          className="w-fit"
                          value={data.classCalendar.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.children?.filter((element) => element.calendarId === selectedCalendarId)[0]?.classCheckedInTags}
                          onChange={(e) => handleSignedInTagChange(e)}
                          placeholder="tags (optional)"
                        />
                        <div className="w-fit place-items-center m-auto">
                          <Checkbox checked={data.classCalendar.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.children?.filter((element) => element.calendarId === selectedCalendarId)[0]?.signedInDateActive === "true"} onChange={(e) => handleSignedInDateTimeSelect(e)}>
                            <div className="flex flex-wrap gap-2">
                              Date-tags
                              <Popover className="my-auto" placement="top" title="Hint?" content={signedInHintContent}>
                                <FaQuestionCircle />
                              </Popover>
                            </div>
                          </Checkbox>
                        </div>
                      </div>
                    )}
                    {data.classCalendar.filter((element) => element.ghl_id === selectedGhlAccount)?.length !== 0 && data.classCalendar.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.children?.filter((element) => element.calendarId === selectedCalendarId)?.length !== 0 && (
                      <div className="flex flex-wrap w-full gap-3">
                        <Input
                          addonBefore="Class noShowed"
                          className="w-fit"
                          value={data.classCalendar.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.children?.filter((element) => element.calendarId === selectedCalendarId)[0]?.classNoShowedTags}
                          onChange={(e) => handleNotSignedInTagChange(e)}
                          placeholder="tags (optional)"
                        />
                        <div className="w-fit place-items-center m-auto">
                          <Checkbox checked={data.classCalendar.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.children?.filter((element) => element.calendarId === selectedCalendarId)[0]?.notSignedInDateActive === "true"} onChange={(e) => handleNotSignedInDateTimeSelect(e)}>
                            <div className="flex flex-wrap gap-2">
                              Date-tags
                              <Popover className="my-auto" placement="top" title="Hint?" content={notSignedInHintContent}>
                                <FaQuestionCircle />
                              </Popover>
                            </div>
                          </Checkbox>
                        </div>
                      </div>
                    )}
                  </Space>
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <div className="w-full mx-auto justify-center text-center place-items-center">
          <Empty className="w-full mx-auto justify-center text-center my-6" description={<span>No tags required</span>}></Empty>
        </div>
      )}

      <UpdateGHLCustomFields open={openCustomFieldModal} setOpen={(bool) => setOpenCustomFieldModal(bool)} manageType={"exist"} data={data} setIsClientFieldPresent={(bool) => setIsClientFieldPresent(bool)} />
    </div>
  );
};

export default ManageMtCustomTags;
