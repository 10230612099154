import { APP_OAUTH_URL } from "./AppApiUrls";

const BASE_URL = `${window.location.protocol}//${window.location.host}${APP_OAUTH_URL}`;
let clientId = "65120d6748c7023aa3a429c1-lu1rsand";
if (window.location.host === "localhost:3000" || window.location.host === "staging.synx.ai") clientId = "663d1c5d68cefcce66365968-lwqpsw3u";

export async function initiateAuth() {
  const options = {
    requestType: "code",
    redirectUri: `${BASE_URL}`,
    clientId: clientId,
    scopes: [
      "calendars.write",
      "calendars.readonly",
      "calendars/events.readonly",
      "calendars/events.write",
      "conversations.readonly",
      "conversations.write",
      "conversations/message.readonly",
      "conversations/message.write",
      "contacts.readonly",
      "contacts.write",
      "locations/customValues.readonly",
      "locations/customValues.write",
      "locations/customFields.readonly",
      "locations/customFields.write",
      "oauth.write",
      "oauth.readonly",
      "locations/tags.readonly",
      "locations/tags.write",
      "locations.readonly",
      "locations.write",
      "conversations/reports.readonly",
      "calendars/groups.readonly",
      "calendars/groups.write",
      "calendars/resources.readonly",
      "calendars/resources.write",
      "invoices.write",
      "invoices/schedule.write",
      "payments/orders.write",
    ],
  };

  // if (window.location.host === "localhost:3000" || window.location.host === "staging.synx.ai")
  return `https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=${options.requestType}&redirect_uri=${options.redirectUri}&client_id=${options.clientId}&scope=${options.scopes.join(" ")}`;
  // else return `https://marketplace.leadconnectorhq.com/oauth/chooselocation?response_type=${options.requestType}&redirect_uri=${options.redirectUri}&client_id=${options.clientId}&scope=${options.scopes.join(" ")}`;
}
