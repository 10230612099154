import { Alert, Button, Form, Input, Select, Space, Typography } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { getCalendarDetailsForGhl } from "../../../../../../helpers/AppUserApi";
import { getClassDetailsForMt, getClassroomDetailsForMt, getReservationTagsForMt, getStaffDetailsForMt } from "../../../../../../helpers/MtApi";
import Search from "antd/es/input/Search";
const { Text } = Typography;

const LinkClassGhlToMtConnection = ({ connectionData, setConnectionData, isExist }) => {
  const [calendarAvailDetails, setCalendarAvailDetails] = useState([]);
  const [classAvailDetails, setClassAvailDetails] = useState([]);
  const [classroomAvailDetails, setClassroomAvailDetails] = useState([]);
  const [reservationtagIds, setReservationTagIds] = useState([]);
  const [staffEmail, setStaffEmail] = useState(null);
  const [dataLoader, setDataLoader] = useState({
    staffEmail: false,
  });

  console.log(connectionData);

  const getMtStaffDetail = () => {
    const toastBox = toast;
    const getMBAvailableClassPromise = getStaffDetailsForMt({
      mt_id: connectionData?.mt_id,
      adminMtId: connectionData?.adminMtId,
    });
    getMBAvailableClassPromise
      .then(
        (data) => {
          if (data) {
            setStaffEmail(data[0]?.attributes?.email);
          }
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  useEffect(() => {
    const getMtCalendarAvail = () => {
      const toastBox = toast;
      const getLocationPromise = getCalendarDetailsForGhl({
        ghl_id: connectionData?.ghl_id,
      });
      getLocationPromise
        .then(
          (data) => {
            if (data) {
              setCalendarAvailDetails(data);
            }
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        });
    };

    const getMtClassAvail = () => {
      const toastBox = toast;
      const getMBAvailableClassPromise = getClassDetailsForMt({
        mt_id: connectionData?.mt_id,
        locationId: connectionData?.locationId,
      });
      getMBAvailableClassPromise
        .then(
          (data) => {
            if (data) {
              setClassAvailDetails(data);
            }
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        });
    };

    const getMtClassroomAvail = () => {
      const toastBox = toast;
      const getMBAvailableClassPromise = getClassroomDetailsForMt({
        mt_id: connectionData?.mt_id,
        locationId: connectionData?.locationId,
      });
      getMBAvailableClassPromise
        .then(
          (data) => {
            if (data) {
              console.log(data);
              setClassroomAvailDetails(data);
            }
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        });
    };

    const getMtTagIds = () => {
      const toastBox = toast;
      const getMBAvailableClassPromise = getReservationTagsForMt({
        mt_id: connectionData?.mt_id,
      });
      getMBAvailableClassPromise
        .then(
          (data) => {
            if (data) {
              setReservationTagIds(data);
            }
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        });
    };
    if (connectionData?.adminMtId) getMtStaffDetail();
    getMtCalendarAvail();
    getMtClassAvail();
    getMtClassroomAvail();
    getMtTagIds();
  }, [connectionData]);

  const handleConnectionRemove = (index) => {
    setConnectionData((prevConnectionData) => {
      const updatedClassCalendar = [...prevConnectionData.classCalendar];
      const updatedObjects = updatedClassCalendar.filter((obj) => obj.index !== index);
      return {
        ...prevConnectionData,
        classCalendar: updatedObjects,
      };
    });
  };

  const handleCalendarSelect = (calendarId, index) => {
    setConnectionData((prevConnectionData) => {
      const updatedClassCalendar = [...prevConnectionData.classCalendar];

      const objectIndex = updatedClassCalendar.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedClassCalendar[objectIndex],
          calendarId: calendarId,
        };

        updatedClassCalendar[objectIndex] = updatedObject;
      }

      return {
        ...prevConnectionData,
        classCalendar: updatedClassCalendar,
      };
    });
  };

  const handleClassSelect = (classTypeId, index) => {
    setConnectionData((prevConnectionData) => {
      const updatedClassCalendar = [...prevConnectionData.classCalendar];

      const objectIndex = updatedClassCalendar.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedClassCalendar[objectIndex],
          classTypeId: classTypeId,
        };

        updatedClassCalendar[objectIndex] = updatedObject;
      }

      return {
        ...prevConnectionData,
        classCalendar: updatedClassCalendar,
      };
    });
  };

  const handleClassroomSelect = (classroomId, index) => {
    setConnectionData((prevConnectionData) => {
      const updatedClassCalendar = [...prevConnectionData.classCalendar];

      const objectIndex = updatedClassCalendar.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedClassCalendar[objectIndex],
          classroomId: classroomId,
        };

        updatedClassCalendar[objectIndex] = updatedObject;
      }

      return {
        ...prevConnectionData,
        classCalendar: updatedClassCalendar,
      };
    });
  };

  const handleTagSelect = (tagIds, index) => {
    setConnectionData((prevConnectionData) => {
      const updatedClassCalendar = [...prevConnectionData.classCalendar];

      const objectIndex = updatedClassCalendar.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedClassCalendar[objectIndex],
          tagIds: tagIds,
        };

        updatedClassCalendar[objectIndex] = updatedObject;
      }

      return {
        ...prevConnectionData,
        classCalendar: updatedClassCalendar,
      };
    });
  };

  const handleClassBookingSelect = (index) => {
    const bookingCurrentStatus = connectionData?.classCalendar.filter((element) => element.index === index)[0]?.bookingActive;

    setConnectionData((prevConnectionData) => {
      const updatedClassCalendar = [...prevConnectionData.classCalendar];

      const objectIndex = updatedClassCalendar.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedClassCalendar[objectIndex],
          bookingActive: bookingCurrentStatus === "true" ? "false" : "true",
        };

        updatedClassCalendar[objectIndex] = updatedObject;
      }

      return {
        ...prevConnectionData,
        classCalendar: updatedClassCalendar,
      };
    });
  };

  const handleClassCancellingSelect = (index) => {
    const cancelCurrentStatus = connectionData?.classCalendar.filter((element) => element.index === index)[0]?.cancelActive;

    setConnectionData((prevConnectionData) => {
      const updatedClassCalendar = [...prevConnectionData.classCalendar];

      const objectIndex = updatedClassCalendar.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedClassCalendar[objectIndex],
          cancelActive: cancelCurrentStatus === "true" ? "false" : "true",
        };

        updatedClassCalendar[objectIndex] = updatedObject;
      }

      return {
        ...prevConnectionData,
        classCalendar: updatedClassCalendar,
      };
    });
  };

  const onSearch = (e) => {
    const toastBox = toast;
    setStaffEmail(e);
    setDataLoader((prevData) => ({ ...prevData, staffEmail: true }));
    const getMBAvailableClassPromise = getStaffDetailsForMt({
      mt_id: connectionData?.mt_id,
      email: e,
    });
    getMBAvailableClassPromise
      .then(
        (data) => {
          if (data) {
            // console.log(data);
            setConnectionData((prevData) => ({ ...prevData, adminMtId: data[0].id }));
          }
        },
        (msg) => {
          toast.error(msg === "USER_NOT_FOUND" ? `User not found. Check again` : `${msg}`, {
            id: toastBox,
          });
          if (connectionData?.adminMtId) getMtStaffDetail();
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        setDataLoader((prevData) => ({ ...prevData, staffEmail: false }));
      });
  };

  //exist handlers

  const handleExistCalendarSelect = (calendarId) => {
    setConnectionData((prevData) => ({
      ...prevData,
      calendarId: calendarId,
      calendarName: calendarAvailDetails.filter((e) => e.id === calendarId)[0]?.name,
    }));
  };

  const handleExistClassSelect = (e) => {
    setConnectionData((prevData) => ({
      ...prevData,
      classTypeId: e,
    }));
  };

  const handleExistClassroomSelect = (e) => {
    setConnectionData((prevData) => ({
      ...prevData,
      classroomId: e,
    }));
  };

  const handleExistTagIdsSelect = (e) => {
    setConnectionData((prevData) => ({
      ...prevData,
      tagIds: e,
    }));
  };

  const handleBookingButtonChange = (data) => {
    const newData = data === "true" ? "false" : "true";
    setConnectionData((prevData) => ({
      ...prevData,
      bookingActive: newData,
    }));
  };

  const handleCancellingButtonChange = (data) => {
    const newData = data === "true" ? "false" : "true";
    setConnectionData((prevData) => ({
      ...prevData,
      cancelActive: newData,
    }));
  };

  return (
    <>
      <Alert message="Note: Both class booking and cancellation share this connection" type="warning" />
      <div className="min-w-[20rem] ml-2 text-start mt-2 border-b pb-2 flex items-center gap-2">
        <Text className="text-base">Enter Staff / Admin email: </Text>
        {/* <Input placeholder="Enter email" className="max-w-[12rem] w-full"  onChange={(e) => setConnectionData({ ...connectionData, adminMtId: e })} /> */}
        <Search placeholder="Enter email" className="max-w-[12rem] w-full" defaultValue={staffEmail ? staffEmail : null} value={staffEmail} loading={dataLoader.staffEmail} onChange={(e) => setStaffEmail(e.target.value)} onSearch={onSearch} />
        <Typography.Text type={connectionData?.adminMtId ? `success` : `secondary`}>{connectionData?.adminMtId ? `Verified` : `Not verified`}</Typography.Text>
      </div>

      <>
        {isExist ? (
          <>
            <div className="grid grid-cols-2 ml-4 gap-2 mt-4">
              <Form.Item
                label="Calendar"
                name="calendar"
                rules={[
                  {
                    required: true,
                    message: "Missing calendar",
                  },
                ]}
              >
                <Select style={{ width: "100%" }} placeholder="Select a calendar" defaultValue={connectionData?.calendarId} onChange={(e) => handleExistCalendarSelect(e)}>
                  {calendarAvailDetails.length !== 0 &&
                    calendarAvailDetails.map((data, index) => {
                      return (
                        <Select.Option key={index} value={data.id}>
                          {data.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Class"
                name="class"
                rules={[
                  {
                    required: true,
                    message: "Missing class",
                  },
                ]}
              >
                <Select style={{ width: "100%" }} placeholder="Select a class" className="ml-6 2xl:ml-0" defaultValue={connectionData?.classTypeId} onChange={(e) => handleExistClassSelect(e)}>
                  {classAvailDetails.length !== 0 &&
                    classAvailDetails.map((data, index) => {
                      return (
                        <Select.Option key={data?.id} value={data.id}>
                          {data.attributes.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item
                label="Classroom"
                name="classroom"
                rules={[
                  {
                    required: true,
                    message: "Missing classroom",
                  },
                ]}
                // className="col-span-2"
              >
                <Select style={{ width: "100%" }} placeholder="Select a classroom" className="ml-0 2xl:ml-0" defaultValue={connectionData?.classroomId} onChange={(e) => handleExistClassroomSelect(e)}>
                  {classroomAvailDetails.length !== 0 &&
                    classroomAvailDetails.map((data, index) => {
                      return (
                        <Select.Option key={data?.id} value={data.id}>
                          {data.attributes.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Select Tags (optional)"
                name="tags"
                rules={[
                  {
                    required: false,
                    message: "Missing classroom",
                  },
                ]}
                className="col-span-2 "
              >
                <Select
                  mode="tags"
                  style={{ minWidth: "50%", width: "fit-content" }}
                  placeholder="Select multiple tags"
                  className="ml-0 2xl:ml-0"
                  defaultValue={connectionData?.tagIds ? (typeof connectionData?.tagIds === "string" ? connectionData?.tagIds.split(",") : connectionData?.tagIds) : []}
                  onChange={(e) => handleExistTagIdsSelect(e)}
                >
                  {reservationtagIds.length !== 0 &&
                    reservationtagIds.map((data, index) => {
                      return (
                        <Select.Option key={data?.id} value={data.id}>
                          {data.attributes.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </div>
            <div className="w-full grid grid-cols-2 gap-4">
              <Form.Item name={"booking"}>
                <Button type="dashed" onClick={() => handleBookingButtonChange(connectionData?.bookingActive)} className={connectionData?.bookingActive === "true" ? "bg-green-400 w-full" : "w-full"}>
                  Booking
                </Button>
              </Form.Item>
              <Form.Item name={"cancelling"}>
                <Button type="dashed" onClick={() => handleCancellingButtonChange(connectionData?.cancelActive)} className={connectionData?.cancelActive === "true" ? "bg-green-400 w-full" : "w-full"}>
                  Cancelling
                </Button>
              </Form.Item>
            </div>
          </>
        ) : (
          <>
            {connectionData?.classCalendar.length !== 0 &&
              connectionData?.classCalendar.map(({ index, calendarId, classTypeId, classroomId, tagIds }) => {
                return (
                  <div key={index} className="grid grid-flow-col mb-6">
                    <div className="grid lg:grid-cols-2 grid-cols-1 mt-2 gap-2">
                      <div className="min-w-[20rem] mx-auto text-start lg:text-center">
                        <Text className="text-base">Select Calendar : </Text>
                        <Select placeholder="Select a calendar" className="max-w-[12rem] w-full" defaultValue={calendarId ? calendarId : null} onChange={(e) => handleCalendarSelect(e, index)}>
                          {calendarAvailDetails.length !== 0 &&
                            calendarAvailDetails.map((element) => {
                              return (
                                <Select.Option key={element.id} value={element.id}>
                                  {element.name}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </div>
                      <div className="min-w-[20rem] mx-auto text-start lg:text-center">
                        <Text className="text-base">Select Class : </Text>
                        <Select placeholder="Select a class" className="max-w-[12rem] w-full" defaultValue={classTypeId ? classTypeId : null} onChange={(e) => handleClassSelect(e, index)}>
                          {classAvailDetails.length !== 0 &&
                            classAvailDetails.map((element) => {
                              return (
                                <Select.Option key={element.id} value={element.id}>
                                  {element.attributes?.name}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </div>
                      <div className="flex items-center min-w-[20rem] text-start lg:text-center gap-1.5 col-span-2">
                        <Text className="text-base flex items-center">Select Classroom : </Text>
                        <Select placeholder="Select a classroom" className="max-w-[12rem] w-full" defaultValue={classroomId ? classroomId : null} onChange={(e) => handleClassroomSelect(e, index)}>
                          {classroomAvailDetails.length !== 0 &&
                            classroomAvailDetails.map((element) => {
                              return (
                                <Select.Option key={element.id} value={element.id}>
                                  {element.attributes?.name}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </div>
                      <div className="min-w-[20rem] flex items-center gap-1.5 text-start lg:text-center col-span-2">
                        <Text className="text-base">Select tags (optional) : </Text>
                        <Select mode="tags" placeholder="Select multiple tags" className="min-w-[30%] w-fit mr-2" defaultValue={tagIds ? tagIds : []} onChange={(e) => handleTagSelect(e, index)}>
                          {reservationtagIds.length !== 0 &&
                            reservationtagIds.map((element) => {
                              return (
                                <Select.Option key={element.id} value={element.id}>
                                  {element.attributes?.name}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </div>
                      <div className="min-w-[20rem] mx-auto text-start lg:text-center">
                        <Button type="dashed" className={connectionData?.classCalendar.filter((element) => element.index === index)[0]?.bookingActive === "true" ? "w-11/12 bg-green-300" : "w-11/12"} onClick={() => handleClassBookingSelect(index)}>
                          Booking
                        </Button>
                      </div>
                      <div className="min-w-[20rem] mx-auto text-start lg:text-center">
                        <Button type="dashed" className={connectionData?.classCalendar.filter((element) => element.index === index)[0]?.cancelActive === "true" ? "w-11/12 bg-green-300" : "w-11/12"} onClick={() => handleClassCancellingSelect(index)}>
                          Cancellation
                        </Button>
                      </div>
                    </div>
                    <div className="w-full place-items-center m-auto justify-center text-center">
                      <MinusCircleOutlined
                        onClick={() => {
                          handleConnectionRemove(index);
                        }}
                      />
                    </div>
                  </div>
                );
              })}

            <div className="w-11/12 mx-auto mt-4">
              <Button
                type="dashed"
                onClick={() => {
                  const length = Number(connectionData?.classCalendar.length + 1);
                  const newValue = {
                    index: length + Math.random(),
                    calendarId: "",
                    className: "",
                    type: "new",
                    bookingActive: "false",
                    cancelActive: "false",
                  };
                  setConnectionData((prevConnectionData) => {
                    return {
                      ...prevConnectionData,
                      classCalendar: [...prevConnectionData.classCalendar, newValue],
                    };
                  });
                }}
                block
                icon={<PlusOutlined />}
              >
                Add connection
              </Button>
            </div>
          </>
        )}
      </>
    </>
  );
};

export default LinkClassGhlToMtConnection;
