import { Alert, Button, Divider, InputNumber, Modal, Select, Typography } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { getCustomFieldDetails } from "../../../../../../helpers/GhlApi";
const { Text } = Typography;

const ServicesMtClientSale = ({ open, setOpen, connectionData, setConnectionData, selectedGhlAccount }) => {
  const [customFields, setCustomFields] = useState([]);
  const [localUpdatedClientSaleData, setLocalUpdatedClientSaleData] = useState(connectionData?.clientSaleEvent?.filter((element) => element.ghl_id === selectedGhlAccount)[0] || null);

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    const reloadClientSaleDetails = () => {
      const toastBox = toast;
      const getCustomFieldPromise = getCustomFieldDetails({ ghl_id: selectedGhlAccount });
      getCustomFieldPromise
        .then(
          (data) => {
            if (data) {
              setCustomFields(data);
            }
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        });
    };
    reloadClientSaleDetails();
  }, [connectionData, selectedGhlAccount]);

  const handleSaveButton = () => {
    let letSave = true;
    if (letSave) {
      //  setConnectionData((prevData) => ({ ...prevData, classCalendar: localUpdatedClientSaleData }))
      setConnectionData((prevData) => {
        let foundMatchingItem = false;

        let updatedClientSaleData = prevData.clientSaleEvent.map((item) => {
          if (item.ghl_id === selectedGhlAccount) {
            foundMatchingItem = true;
            return {
              ...item,
              ...localUpdatedClientSaleData,
            };
          }

          return item;
        });

        if (!foundMatchingItem) {
          setLocalUpdatedClientSaleData({
            ghl_id: selectedGhlAccount,
            isActive: "true",
            ...localUpdatedClientSaleData,
          });
          updatedClientSaleData.push({
            ghl_id: selectedGhlAccount,
            isActive: "true",
            ...localUpdatedClientSaleData,
          });
        }
        return {
          ...prevData,
          clientSaleEvent: updatedClientSaleData,
        };
      });
    } else toast.error(`Check your selection!`);
  };

  const handleAmountFieldSelect = (customFieldID) => {
    setLocalUpdatedClientSaleData({ ...localUpdatedClientSaleData, purchaseAmountFieldID: customFieldID });
  };

  const handleNameFieldSelect = (customFieldID) => {
    setLocalUpdatedClientSaleData({ ...localUpdatedClientSaleData, itemNameFieldID: customFieldID });
  };

  const handleTypeFieldSelect = (customFieldID) => {
    setLocalUpdatedClientSaleData({ ...localUpdatedClientSaleData, itemTypeFieldID: customFieldID });
  };

  const onDelayInputChange = (value) => {
    console.log(localUpdatedClientSaleData);
    setLocalUpdatedClientSaleData({ ...localUpdatedClientSaleData, delay: value });
  };

  const handleActiveSelect = () => {
    setLocalUpdatedClientSaleData((prevData) => {
      return { ...localUpdatedClientSaleData, isActive: prevData?.isActive === "true" ? "false" : "true" };
    });
  };

  return (
    <Modal
      title={"Manage client sale event"}
      className="max-w-3xl"
      width={"70vw"}
      centered
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Return
        </Button>,
        <Fragment key="save">
          {localUpdatedClientSaleData &&
          (localUpdatedClientSaleData?.purchaseAmountFieldID || localUpdatedClientSaleData?.itemNameFieldID || localUpdatedClientSaleData?.itemTypeFieldID) &&
          JSON.stringify(localUpdatedClientSaleData) !== JSON.stringify(connectionData?.clientSaleEvent.filter((element) => element.ghl_id === selectedGhlAccount)[0]) ? (
            <Button key="save" type="default" className="bg-red-300" onClick={handleSaveButton}>
              Save & continue
            </Button>
          ) : (
            <Button key="save" type="default" className="bg-red-300" disabled>
              Save & continue
            </Button>
          )}
        </Fragment>,
      ]}
    >
      <Divider className="my-0.5" />

      <div className="flex justify-between items-center my-auto">
        <div className="pt-2 my-auto">
          <Typography.Title level={5} type="secondary">
            This event will update GHL custom fields
          </Typography.Title>
        </div>

        <Button key="save" type="dashed" className={`${localUpdatedClientSaleData?.isActive === "true" ? `bg-green-300` : `bg-gray-100`} `} onClick={() => handleActiveSelect()}>
          {localUpdatedClientSaleData?.isActive === "true" ? `Active` : `Inactive`}
        </Button>
      </div>

      <Divider className="my-0.5" />
      <div className="grid grid-flow-col mb-6">
        <div className="grid lg:grid-cols-2 grid-cols-1 mt-2 gap-2">
          <div className="flex mx-auto justify-start ">
            <Text className="text-base min-w-fit">Item amount : </Text>
            <Select
              placeholder="Select a custom field"
              className="grid-cols-1 max-w-[12rem] text-start justify-start ml-4"
              defaultValue={connectionData?.clientSaleEvent?.length !== 0 ? connectionData?.clientSaleEvent.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.purchaseAmountFieldID : null}
              onChange={(e) => handleAmountFieldSelect(e)}
            >
              {customFields.length !== 0 &&
                customFields.map((element) => {
                  return (
                    <Select.Option key={element.id} value={element.id}>
                      {element.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </div>
          <div className="flex mx-auto justify-start ">
            <Text className="grid-cols-1 text-base min-w-fit">Item name : </Text>
            <Select
              placeholder="Select a custom field"
              className="grid-cols-1 max-w-[12rem] text-start justify-start ml-4"
              defaultValue={connectionData?.clientSaleEvent?.length !== 0 ? connectionData?.clientSaleEvent.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.itemNameFieldID : null}
              onChange={(e) => handleNameFieldSelect(e)}
            >
              {customFields.length !== 0 &&
                customFields.map((element) => {
                  return (
                    <Select.Option key={element.id} value={element.id}>
                      {element.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </div>
          <div className="flex mx-auto justify-start ">
            <Text className="text-base min-w-fit">Item type : </Text>
            <Select
              placeholder="Select a custom field"
              className="grid-cols-1 max-w-[12rem] text-start justify-start ml-4"
              defaultValue={connectionData?.clientSaleEvent?.length !== 0 ? connectionData?.clientSaleEvent.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.itemTypeFieldID : null}
              onChange={(e) => handleTypeFieldSelect(e)}
            >
              {customFields.length !== 0 &&
                customFields.map((element) => {
                  return (
                    <Select.Option key={element.id} value={element.id}>
                      {element.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </div>
          <div className="flex mx-auto justify-start ">
            <Text className="text-base min-w-fit">
              Delay
              <Text> (for multiple items) :</Text>
            </Text>
            <InputNumber
              className="grid-cols-1 max-w-[12rem] text-start justify-start ml-4"
              defaultValue={connectionData?.clientSaleEvent?.length !== 0 ? connectionData?.clientSaleEvent.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.delay : 10}
              min={0}
              max={120}
              formatter={(value) => `${value} sec`}
              parser={(value) => value.replace(" sec", "")}
              onChange={onDelayInputChange}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ServicesMtClientSale;
