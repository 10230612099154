import React, { useEffect, useState } from "react";
import { Input, Select, Spin, Typography } from "antd";
import { getGHLAvailAccounts } from "../../../../../../helpers/AppUserApi";
import { toast } from "react-hot-toast";
import { getMtAvailAccounts, getMtAvailLocations } from "../../../../../../helpers/MtApi";
const { Option } = Select;

const LinkAccountGhlToMtConnection = ({ data, setData, isExist }) => {
  const [ghlAvailAccounts, setGhlAvailAccounts] = useState([]);
  const [mtAvailAccounts, setMtAvailAccounts] = useState([]);
  const [mtAvailLocations, setMtAvailLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataLoader, setDataLoader] = useState({
    ghlAccounts: false,
    mtAccounts: false,
    mtLocations: null,
  });

  const handleGhlAccountSelect = (e) => {
    setData({ ...data, ghl_id: e });
  };

  const handleMTAccountSelect = (e) => {
    setData({ ...data, mt_id: e });
  };

  const handleMTLocationSelect = (e) => {
    setData({ ...data, locationId: e });
  };

  const getGHLContactAvailAccFunc = () => {
    let toastBox = toast;
    const getAvailAccountPromise = getGHLAvailAccounts();
    getAvailAccountPromise
      .then(
        (data) => {
          if (data) setGhlAvailAccounts(data);
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        setDataLoader((prevData) => ({ ...prevData, ghlAccounts: true }));
      });
  };

  const getMtContactAvailAccFunc = () => {
    let toastBox = toast;
    const getAvailAccountPromise = getMtAvailAccounts();
    getAvailAccountPromise
      .then(
        (data) => {
          if (data) setMtAvailAccounts(data);
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        setDataLoader((prevData) => ({ ...prevData, mtAccounts: true }));
      });
  };

  // const getMtLocationAvailAccFunc = () => {
  //   let toastBox = toast;
  //   setDataLoader((prevData) => ({ ...prevData, mtLocations: false }));
  //   const getAvailAccountPromise = getMtAvailLocations(data?.mt_id);
  //   getAvailAccountPromise
  //     .then(
  //       (data) => {
  //         console.log(data);
  //         if (data) setMtAvailLocations(data);
  //       },
  //       (msg) => {
  //         toast.error(`${msg}`, {
  //           id: toastBox,
  //         });
  //       }
  //     )
  //     .catch((err) => {
  //       toast.error(`${err}`, {
  //         id: toastBox,
  //       });
  //     })
  //     .finally(() => {
  //       setDataLoader((prevData) => ({ ...prevData, mtLocations: true }));
  //     });
  // };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setData((prevData) => ({
      ...prevData,
      connectionName: value,
    }));
  };

  // useEffect(() => {
  //   if (data?.mt_id && data?.mt_id !== "") getMtLocationAvailAccFunc();
  // }, [data?.mt_id]);

  useEffect(() => {
    getGHLContactAvailAccFunc();
    getMtContactAvailAccFunc();
  }, []);

  useEffect(() => {
    if (dataLoader.ghlAccounts && dataLoader.mtAccounts && (dataLoader.mtLocations === null || dataLoader.mtLocations)) setLoading(false);
    else setLoading(true);
  }, [dataLoader]);

  return (
    <Spin spinning={loading} className="my-6">
      <div className="w-10/12 mx-auto mt-2">
        <Input addonBefore="Connection name" onChange={handleInputChange} value={data?.connectionName || data?.name} placeholder="Enter Name" className="max-w-lg mb-4" />
        <div>
          <Typography.Text>GoHighLevel account : </Typography.Text>
          <Select placeholder="Select an account" className="ml-3.5 lg:max-w-xs lg:w-1/2 xl:w-full" disabled={isExist} onChange={handleGhlAccountSelect} defaultValue={data?.ghl_id !== "" ? data?.ghl_id : null}>
            {ghlAvailAccounts.length !== 0 &&
              ghlAvailAccounts.map((data, index) => {
                return (
                  <Option key={index} value={data.id}>
                    {data.name}
                  </Option>
                );
              })}
          </Select>
        </div>

        <div className="mt-4">
          <Typography.Text>MarianaTek account : </Typography.Text>
          <Select placeholder="Select an account" className="ml-6 lg:max-w-xs lg:w-1/2 xl:w-full" disabled={isExist} onChange={handleMTAccountSelect} defaultValue={data?.mt_id !== "" ? data?.mt_id : null}>
            {mtAvailAccounts.length !== 0 &&
              mtAvailAccounts.map((data, index) => {
                return (
                  <Option key={index} value={data?.id}>
                    {data?.name}
                  </Option>
                );
              })}
          </Select>
        </div>
        {/* <div className="mt-4">
          <Typography.Text>MarianaTek location : </Typography.Text>
          <Select placeholder="Select a location" className="ml-6 lg:max-w-xs lg:w-1/2 xl:w-full" disabled={isExist} onChange={handleMTLocationSelect} defaultValue={data?.locationId !== "" ? data?.locationId : null}>
            {mtAvailLocations.length !== 0 &&
              mtAvailLocations.map((data) => {
                return (
                  <Option key={data?.id} value={data?.id}>
                    {data?.attributes?.name}
                  </Option>
                );
              })}
          </Select>
        </div> */}
      </div>
    </Spin>
  );
};
export default LinkAccountGhlToMtConnection;
