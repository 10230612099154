import React, { useEffect, useState } from "react";
import { EditOutlined, DeleteOutlined, ReloadOutlined } from "@ant-design/icons";
import { getGHLIntegrationDetails, getMBIntegrationDetails } from "../../../../../helpers/AppUserApi";
import { toast } from "react-hot-toast";
import EditModalGhl from "./EditModalGhl";
import EditModalMb from "./EditModalMb";
import { deleteGhlData } from "../../../../../helpers/GhlApi";
import { deleteMBData } from "../../../../../helpers/WebhookApi";
import { List } from "antd";
import { confirmModalRef } from "../../../../../components/ConfirmModal";
import { deleteMtAccount, getMtAccounts } from "../../../../../helpers/MtApi";
import EditModalMt from "./EditModalMt";
import { initiateAuth } from "../../../../../helpers/OauthApi";

const IntegrationTable = () => {
  let toastBox = toast;
  const [ghlDetails, setGhlDetails] = useState([]);
  const [editGhlDetails, setEditGhlDetails] = useState([]);
  const [mbDetails, setMbDetails] = useState([]);
  const [mtDetails, setMtDetails] = useState([]);
  const [editMtDetails, setEditMtDetails] = useState([]);
  const [editMbDetails, setEditMbDetails] = useState([]);
  const [editGhlModal, setEditGhlModal] = useState(false);
  const [editMtModal, setEditMtModal] = useState(false);
  const [editMbModal, setEditMbModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalIntegrationsData, setTotalIntegrationsData] = useState([]);

  const getGHLIntegrationDetailsFunc = () => {
    setLoading(true);
    const getGHLIntegrationsPromise = getGHLIntegrationDetails();
    getGHLIntegrationsPromise
      .then(
        (data) => {
          setGhlDetails(data);
        },
        (msg) => {
          if (msg.includes("401")) {
            toast.error("Invalid user", {
              id: toastBox,
            });
          } else {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getMBIntegrationDetailsFunc = () => {
    setLoading(true);
    const getMBIntegrationsPromise = getMBIntegrationDetails();
    getMBIntegrationsPromise
      .then(
        (data) => {
          console.log(data);
          setMbDetails(data);
        },
        (msg) => {
          if (msg.includes("401")) {
            toast.error("Invalid user", {
              id: toastBox,
            });
          } else {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getMTIntegrationDetailsFunc = () => {
    setLoading(true);
    const getMBIntegrationsPromise = getMtAccounts();
    getMBIntegrationsPromise
      .then(
        (data) => {
          console.log(data);
          setMtDetails(data);
        },
        (msg) => {
          if (msg.includes("401")) {
            toast.error("Invalid user", {
              id: toastBox,
            });
          } else {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEditModal = (item) => {
    if (item?.type === "ghl") {
      setEditGhlDetails(ghlDetails.filter((element) => item.id === element.id));
      setEditGhlModal(true);
    } else if (item?.type === "mt") {
      setEditMtDetails(mtDetails.filter((element) => item.id === element.id));
      setEditMtModal(true);
    } else {
      const siteid = item.siteid;
      const apikey = item.apikey;
      setEditMbDetails(mbDetails.filter((element) => apikey === element.apikey && siteid === element.siteid));
      setEditMbModal(true);
    }
  };

  const handleReSyncModal = async () => {
    const redirectURL = await initiateAuth();
    window.location.href = redirectURL;
  };

  const handleDeleteModal = (item) => {
    confirmModalRef.current.open("This will delete all connections using this integrated account permanently!", "Are you sure?", async () => {
      if (item?.type === "ghl") {
        try {
          const deleteGhlDataPromise = deleteGhlData({ id: item.id });
          deleteGhlDataPromise
            .then(
              (data) => {
                toast.success("Account Integration Deleted", {
                  id: toastBox,
                });
                window.location.reload();
              },
              (msg) => {
                toast.error(`${msg}`, {
                  id: toastBox,
                });
              }
            )
            .catch((err) => {
              toast.error(`${err}`, {
                id: toastBox,
              });
            });
        } catch (error) {
          console.log(error);
        }
      } else if (item?.type === "mt") {
        try {
          const deleteGhlDataPromise = deleteMtAccount({ mt_id: item.id });
          deleteGhlDataPromise
            .then(
              (data) => {
                toast.success("Account Integration Deleted", {
                  id: toastBox,
                });
                window.location.reload();
              },
              (msg) => {
                toast.error(`${msg}`, {
                  id: toastBox,
                });
              }
            )
            .catch((err) => {
              toast.error(`${err}`, {
                id: toastBox,
              });
            });
        } catch (error) {
          console.log(error);
        }
      } else {
        const siteid = item.siteid;
        const apikey = item.apikey;
        const deleteMBDataPromise = deleteMBData({
          apikey: apikey,
          siteid: siteid,
        });
        deleteMBDataPromise
          .then(
            (data) => {
              toast.success("Account Integration Deleted", {
                id: toastBox,
              });
              window.location.reload();
            },
            (msg) => {
              toast.error(`${msg}`, {
                id: toastBox,
              });
            }
          )
          .catch((err) => {
            toast.error(`${err}`, {
              id: toastBox,
            });
          });
      }
    });
  };

  useEffect(() => {
    getGHLIntegrationDetailsFunc();
    getMBIntegrationDetailsFunc();
    getMTIntegrationDetailsFunc();
  }, []);

  useEffect(() => {
    const updatedGhlIntegrationData =
      ghlDetails
        ?.filter((item) => !totalIntegrationsData.some((existingItem) => existingItem.id === item.id))
        ?.map((item, index) => ({
          ...item,
          type: "ghl",
          index: totalIntegrationsData.length + index + 1,
        })) || [];
    const updatedMbIntegrationData =
      mbDetails
        ?.filter((item) => !totalIntegrationsData.some((existingItem) => existingItem.id === item.id))
        ?.map((item, index) => ({
          ...item,
          type: "mb",
          index: totalIntegrationsData.length + updatedGhlIntegrationData.length + index + 1,
        })) || [];
    const updatedMtIntegrationData =
      mtDetails
        ?.filter((item) => !totalIntegrationsData.some((existingItem) => existingItem.id === item.id))
        ?.map((item, index) => ({
          ...item,
          type: "mt",
          index: totalIntegrationsData.length + updatedGhlIntegrationData.length + updatedMbIntegrationData.length + index + 1,
        })) || [];
    setTotalIntegrationsData((prev) => [...prev, ...updatedGhlIntegrationData, ...updatedMbIntegrationData, ...updatedMtIntegrationData]);
    console.log(totalIntegrationsData);
  }, [ghlDetails, mbDetails, mtDetails]);

  return (
    <>
      <>
        <EditModalGhl open={editGhlModal} setOpen={(bool) => setEditGhlModal(bool)} ghlDetails={editGhlDetails} />
        <EditModalMb open={editMbModal} setOpen={(bool) => setEditMbModal(bool)} mbDetails={editMbDetails} />
        <EditModalMt open={editMtModal} setOpen={(bool) => setEditMtModal(bool)} mtDetails={editMtDetails[0]} />
      </>
      <div className="px-4 justify-center mx-auto">
        <List
          itemLayout="horizontal"
          loading={loading}
          dataSource={totalIntegrationsData}
          pagination={{
            pageSize: 10,
          }}
          renderItem={(item) => (
            <List.Item
              actions={[
                <button type="button" key="list-loadmore-edit" className="text-amber-500 text-lg" onClick={() => handleEditModal(item)}>
                  <EditOutlined />
                </button>,
                <button type="button" key="list-loadmore-more" className="text-red-600 text-lg" onClick={() => handleDeleteModal(item)}>
                  <DeleteOutlined />
                </button>,
              ]}
            >
              <List.Item.Meta
                avatar={<span>{item.index}.</span>}
                title={
                  <div className="gap-2 flex items-center">
                    <span className="capitalize">{item.name}</span>
                    {item?.type === "ghl" && (
                      <button type="button" key="list-loadmore-edit" className="text-blue-500 text-lg" onClick={() => handleReSyncModal(item)}>
                        <ReloadOutlined />
                      </button>
                    )}
                  </div>
                }
                description={
                  <div className="">
                    {item?.type === "ghl" && <span className=" pr-2">API Version: {item?.accessToken ? "V2.0" : "V1.1"}</span>}
                    {item?.type === "mb" && <span className=" pr-2">Site Id: {item?.siteid}</span>}
                    {item?.type === "mt" && <span className=" pr-2">Sub Domain: {item?.subdomain}</span>}
                  </div>
                }
              />
              <div>{item?.type === "ghl" ? "GoHighLevel" : item?.type === "mb" ? "Mindbody" : "MarianaTek"}</div>
            </List.Item>
          )}
        />
      </div>
    </>
  );
};

export default IntegrationTable;
