import axios from "axios";
import { API_URL, APP_MT_BASE_URL } from "./AppApiUrls";

const BASE_URL = `${API_URL}${APP_MT_BASE_URL}`;
//const BASE_URL = "http://localhost:8000/app/api/user";
//const BASE_URL = "http://13.232.11.86:8000/app/api/user";
//const BASE_URL = "http://192.168.0.109:8000/app/api/user"

export async function getMtAvailAccounts() {
  try {
    const { data } = await axios.get(`${BASE_URL}/account`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    // console.log(data)
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.reason);
  } catch (err) {
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}

export async function getMtAvailLocations(mt_id) {
  try {
    console.log("mt_id: " + mt_id);
    const { data } = await axios.get(`${BASE_URL}/account/locations`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      params: { mt_id: mt_id },
    });
    // console.log(data)
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.reason);
  } catch (err) {
    console.log(err?.message);
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}

export async function addGhlToMtContactWebhook(contactdetails) {
  try {
    console.log(contactdetails);
    const { data } = await axios.post(`${BASE_URL}/contact/webhook/add`, contactdetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.reason);
  } catch (err) {
    console.log(err?.message);
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}

export async function updateGhlToMtContactWebhook(appdetails) {
  try {
    const { data } = await axios.put(`${BASE_URL}/contact/webhook`, appdetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.reason);
  } catch (err) {
    console.log(err?.message);
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}

export async function getGhlToMtContactWebhooks() {
  try {
    const { data } = await axios.get(`${BASE_URL}/contact/webhook/`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    console.log(data);
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.reason);
  } catch (err) {
    console.log(err?.message);
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}

export async function deleteGhlToMtContactWebhook(userdetails) {
  try {
    const { data } = await axios.delete(`${BASE_URL}/contact/webhook`, {
      data: userdetails,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.reason);
  } catch (err) {
    console.log(err?.message);
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}

export async function getClassDetailsForMt(apidetails) {
  try {
    const { data } = await axios.get(`${BASE_URL}/reservation/class-types`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      params: apidetails,
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.reason);
  } catch (err) {
    console.log(err?.message);
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}

export async function getReservationTagsForMt(apidetails) {
  try {
    const { data } = await axios.get(`${BASE_URL}/reservation/tags`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      params: apidetails,
    });
    console.log(data);
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.reason);
  } catch (err) {
    console.log(err?.message);
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}

export async function getClassroomDetailsForMt(apidetails) {
  try {
    const { data } = await axios.get(`${BASE_URL}/reservation/class-room`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      params: apidetails,
    });
    console.log(data);
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.reason);
  } catch (err) {
    console.log(err?.message);
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}

export async function addGhlToMtClassBookingWebhook(appdetails) {
  try {
    const { data } = await axios.post(`${BASE_URL}/reservation/webhook/add`, appdetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.reason);
  } catch (err) {
    console.log(err?.message);
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}

export async function getStaffDetailsForMt(apidetails) {
  try {
    const { data } = await axios.get(`${BASE_URL}/reservation/staff/verification`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      params: apidetails,
    });
    console.log(data);
    if (data?.success) return Promise.resolve(data?.data);
    else {
      if (typeof data?.reason === "object") {
        return Promise.reject(JSON.stringify(data?.reason));
      } else {
        return Promise.reject(data?.reason);
      }
    }
  } catch (err) {
    console.log(err?.message);
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}

export async function getGhlToMtClassWebhooks() {
  try {
    const { data } = await axios.get(`${BASE_URL}/reservation/webhook`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    // console.log(data)
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.reason);
  } catch (err) {
    console.log(err?.message);
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}

export async function updateGhlToMtClassWebhook(appdetails) {
  try {
    const { data } = await axios.put(`${BASE_URL}/reservation/webhook`, appdetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.reason);
  } catch (err) {
    console.log(err?.message);
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}

export async function deleteGhlToMtClassWebhook(userdetails) {
  try {
    const { data } = await axios.delete(`${BASE_URL}/reservation/webhook`, {
      data: userdetails,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.reason);
  } catch (err) {
    console.log(err?.message);
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}

export async function getGhlToMtMembershipWebhooks() {
  try {
    const { data } = await axios.get(`${BASE_URL}/membership/webhook`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    // console.log(data)
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.reason);
  } catch (err) {
    console.log(err?.message);
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}

export async function getMtPartnersList(apidetails) {
  try {
    const { data } = await axios.get(`${BASE_URL}/membership/partners`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      params: apidetails,
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.reason);
  } catch (err) {
    console.log(err?.message);
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}

export async function getMtProductsList(apidetails) {
  try {
    const { data } = await axios.get(`${BASE_URL}/membership/products`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      params: apidetails,
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.reason);
  } catch (err) {
    console.log(err?.message);
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}

export async function addGhlToMtMembershipWebhook(appdetails) {
  try {
    const { data } = await axios.post(`${BASE_URL}/membership/webhook/add`, appdetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.reason);
  } catch (err) {
    console.log(err?.message);
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}

export async function updateGhlToMtMembershipWebhook(appdetails) {
  try {
    const { data } = await axios.put(`${BASE_URL}/membership/webhook`, appdetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.reason);
  } catch (err) {
    console.log(err?.message);
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}

export async function deleteGhlToMtMembershipWebhook(userdetails) {
  try {
    const { data } = await axios.delete(`${BASE_URL}/membership/webhook`, {
      data: userdetails,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.reason);
  } catch (err) {
    console.log(err?.message);
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}

export async function verifyMtAccountAndGetLocation(subdomain) {
  try {
    const { data } = await axios.get(`${BASE_URL}/account/verify`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      params: { subdomain },
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.reason);
  } catch (err) {
    console.log(err?.message);
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}

export async function addMtAccount(appdetails) {
  try {
    const { data } = await axios.post(`${BASE_URL}/account/add`, appdetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.reason);
  } catch (err) {
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}

export async function updateMtAccount(appdetails) {
  try {
    const { data } = await axios.put(`${BASE_URL}/account/update`, appdetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.reason);
  } catch (err) {
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}

export async function getMtAccounts() {
  try {
    const { data } = await axios.get(`${BASE_URL}/account`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.reason);
  } catch (err) {
    console.log(err?.message);
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}

export async function deleteMtAccount(userdetails) {
  try {
    const { data } = await axios.delete(`${BASE_URL}/account`, {
      data: userdetails,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.reason);
  } catch (err) {
    console.log(err?.message);
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}

export async function addMTWebhook(contactdetails) {
  try {
    const { data } = await axios.post(`${BASE_URL}/webhook/add`, contactdetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      //  data: filterOptions,
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.reason);
  } catch (err) {
    console.log(err?.message);
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}

export async function updateMTWebhook(contactdetails) {
  try {
    const { data } = await axios.post(`${BASE_URL}/webhook/update`, contactdetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      //  data: filterOptions,
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.reason);
  } catch (err) {
    console.log(err?.message);
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}

export async function getMtClassCalendarDetails(apidetails) {
  try {
    const { data } = await axios.post(`${BASE_URL}/webhook/class-connections`, apidetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.reason);
  } catch (err) {
    console.log(err?.message);
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}

export async function getMarianatekWebhooks() {
  try {
    const { data } = await axios.get(`${BASE_URL}/webhook/webhooks`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    console.log(data);
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.reason);
  } catch (err) {
    console.log(err?.message);
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}

export async function updateMtWebhook(contactdetails) {
  try {
    const { data } = await axios.post(`${BASE_URL}/webhook/update`, contactdetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.reason);
  } catch (err) {
    console.log(err?.message);
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}

export async function deleteMarianatekWebhook(webdetails) {
  try {
    const { data } = await axios.delete(`${BASE_URL}/webhook/delete`, { data: webdetails, headers: { "Content-Type": "application/json" }, withCredentials: true });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.reason);
  } catch (err) {
    console.log(err?.message);
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}
