import React, { useEffect, useState } from "react";
import AddConnectionModal from "./subComponents/AddConnectionModal";
import ConnectionTableNewUI from "./subComponents/ConnectionTableNewUI";
import { Card, CardBody, Input, Spinner, Tab, Tabs, TabsBody, TabsHeader } from "@material-tailwind/react";
import { Button, Space, Typography } from "antd";
import AddIntegrationModal from "./subComponents/connectionAddition/globalComponents/AddIntegrationModal";
import SyncModal from "./subComponents/linkedAccounts/SyncModal";
import IntegrationTable from "./subComponents/linkedAccounts/IntegrationTable";
import { mbToGhlContactSyncStart, mbToGhlContactSyncStop, mbToGhlSyncStatus } from "../../../helpers/MbApi";

const Connections = () => {
  const [addConnectionModal, setAddConnectionModal] = useState(false);
  const [activeTab, setActiveTab] = useState("connection");
  const [addIntegrationModal, setAddIntegrationModal] = useState(false);
  const [syncModal, setSyncModal] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);

  const handleAddConnectionModal = (e) => {
    setAddConnectionModal(true);
  };

  const handleAddIntegrationModal = (e) => {
    setAddIntegrationModal(true);
  };

  const handleSyncModal = (e) => {
    setSyncModal(true);
  };

  const handleSyncSubmit = async (mb_apikey, siteid, ghl_apikey, transferLimit) => {
    try {
      setIsSyncing(true);
      const handleSyncContactResult = await mbToGhlContactSyncStart({
        mb_apikey,
        siteid,
        ghl_apikey,
        transferLimit,
      });
      setIsSyncing(false);
    } catch (error) {
      console.log(error);
    }
  };

  const stopSync = async () => {
    try {
      const handleSyncContactResult = await mbToGhlContactSyncStop();
      setIsSyncing(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getSyncStatusFunc = async () => {
      try {
        const handleSyncContactResult = await mbToGhlSyncStatus();
        const status = handleSyncContactResult?.status;
        setIsSyncing(status === "active" ? true : false);
      } catch (error) {
        console.log(error);
      }
    };
    getSyncStatusFunc();
  }, []);

  return (
    <div className="md:w-full w-11/12">
      <div className="overflow-y-visible bg-red-300" style={{ maxHeight: "90vh" }}>
        <AddConnectionModal open={addConnectionModal} setOpen={(bool) => setAddConnectionModal(bool)} />
      </div>

      <div className="w-full sm:pl-16 sm:text-start mx-auto text-center">
        <h1 className="text-2xl">Connections</h1>
      </div>
      <div className="w-11/12 mt-0.5 mx-auto justify-center">
        <hr />
      </div>
      {/* <div className="w-full sm:pl-14 pt-5 sm:text-start mx-auto text-center">
        
        <AddConnectionDropdown />
      </div> */}

      <Tabs value={activeTab}>
        <div className="w-2/4 mx-auto mt-4">
          <TabsHeader className="min-w-max max-w-lg mx-auto">
            <Tab
              value={"connection"}
              onClick={() => {
                setActiveTab("connection");
              }}
              className={activeTab === "connection" ? "text-blue-500 min-w-max" : "min-w-max"}
            >
              Manage connections
            </Tab>
            <Tab value={"account"} onClick={() => setActiveTab("account")} className={activeTab === "account" ? "text-blue-500" : ""}>
              Linked accounts
            </Tab>
          </TabsHeader>
        </div>

        <TabsBody>
          {activeTab === "account" && (
            <div className="md:w-11/12 justify-center mx-auto bg-white p-4 shadow-md rounded-lg my-4">
              <AddIntegrationModal open={addIntegrationModal} setOpen={(bool) => setAddIntegrationModal(bool)} />
              <SyncModal open={syncModal} setOpen={(bool) => setSyncModal(bool)} handleSyncSubmit={(mb_apikey, siteid, ghl_apikey, transferLimit) => handleSyncSubmit(mb_apikey, siteid, ghl_apikey, transferLimit)} />
              <div className="w-full py-5 sm:text-start mx-auto text-center">
                <Space direction="horizontal">
                  <Button color="green" onClick={handleAddIntegrationModal}>
                    Add Integration
                  </Button>
                  {isSyncing ? (
                    <>
                      <Button className="flex items-center" onClick={() => stopSync()}>
                        <Spinner width={15} />
                        <span className=" pl-2">Stop</span>
                      </Button>
                      <Typography.Text type="secondary">It may take a while ...</Typography.Text>
                    </>
                  ) : (
                    <Button color="green" onClick={handleSyncModal}>
                      SYNC Accounts
                    </Button>
                  )}
                  {/* <Button onClick={startWebSocket} disabled={isWebSocketOn}>
                    Start WebSocket
                  </Button> */}
                  {/* {isSyncing && <Button onClick={() => stopSync()}>Stop</Button>} */}
                </Space>

                {/* <Button color="amber" onClick={handleSyncModal} className="ml-4">
                 SYNC
               </Button> */}
              </div>
              <IntegrationTable />
            </div>
          )}

          {activeTab === "connection" && (
            <div className="w-11/12 pt-5 mx-auto justify-center " style={{ maxHeight: "max-content" }}>
              <ConnectionTableNewUI />
            </div>
          )}
        </TabsBody>
      </Tabs>
    </div>
  );
};

export default Connections;
