import { Button, Divider, Select, Space, Typography } from "antd";
import Search from "antd/es/input/Search";
import React, { useEffect, useState } from "react";
import ServicesMtClassCalendar from "./ServicesMtClassCalendar";
import { toast } from "react-hot-toast";
import { getGHLAvailAccounts } from "../../../../../../helpers/AppUserApi";
import { getMtAvailLocations } from "../../../../../../helpers/MtApi";
import ServicesMtContractCreated from "./ServicesMtContractCreated";
import ServicesMtClientCreated from "./ServicesMtClientCreated";
import ServicesMtClientSale from "./ServicesMtClientSale";
import ServicesMtMembershipCancel from "./ServicesMtMembershipCancel";
import ServicesMtContractUpdate from "./ServicesMtContractUpdate";
import ServicesMtClientUpdate from "./ServicesMtClientUpdate";

const LinkMtServicesConnection = ({ data, setData }) => {
  const [servicesSelected, setServicesSelected] = useState({
    manageClass: false,
    manageAppointment: false,
    clientCreated: false,
    clientUpdate: false,
    membershipAdd: false,
    membershipCancel: false,
    membershipDeclined: false,
  });

  const [manageModal, setManageModal] = useState({
    manageClass: false,
    clientSale: false,
    clientCreated: false,
    membershipAdd: false,
    membershipCancel: false,
    clientUpdate: false,
    membershipDeclined: false,
  });

  const [ghlAvailAccounts, setGhlAvailAccounts] = useState([]);
  const [mtLocations, setMtLocations] = useState([]);
  const [selectedGhlAccount, setSelectedGhlAccount] = useState(null);

  const handleSelectGhlAccount = (e) => {
    setSelectedGhlAccount(e);
  };

  const getGHLContactAvailAccFunc = () => {
    let toastBox = toast;
    const getAvailAccountPromise = getGHLAvailAccounts();
    getAvailAccountPromise
      .then(
        (data) => {
          if (data) setGhlAvailAccounts(data);
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
    // .finally(() => {
    //   setDataLoader((prevData) => ({ ...prevData, ghlAccounts: true }));
    // });
  };

  const getMtLocationAvailAccFunc = () => {
    let toastBox = toast;
    const getAvailAccountPromise = getMtAvailLocations(data?.mt_id);
    getAvailAccountPromise
      .then(
        (data) => {
          if (data) setMtLocations(data);
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  useEffect(() => {
    getGHLContactAvailAccFunc();
    getMtLocationAvailAccFunc();
  }, []);

  const handleSelectServiceButton = (keyToUpdate) => {
    // Create a copy of the current state object
    const updatedServicesSelected = { ...servicesSelected };

    // Update the value of the property using the variable key
    updatedServicesSelected[keyToUpdate] = !updatedServicesSelected[keyToUpdate]; // Set the value to true (or any other value you want)

    let updateType = null;
    // Update the state with the new object
    if (keyToUpdate === "clientCreated") updateType = "clientCreated";
    else if (keyToUpdate === "clientUpdate") updateType = "clientUpdate";
    else if (keyToUpdate === "membershipAdd") updateType = "membershipAdd";
    else if (keyToUpdate === "membershipCancel") updateType = "membershipCancel";
    else if (keyToUpdate === "membershipDeclined") updateType = "membershipDeclined";
    else if (keyToUpdate === "classCalendar") setData((prevData) => ({ ...prevData, classCalendarActive: !data.classCalendarActive }));

    // if (updateType) {
    //   setData((prevData) => {
    //     const updatedClientCreatedData = prevData.otherEvents.map((item) => {
    //       if (item.ghl_id === selectedGhlAccount) {
    //         return {
    //           ...item,
    //           [updateType]: item[updateType] === true ? false : true,
    //         };
    //       }
    //       return item;
    //     });

    //     const matchingItem = prevData.otherEvents.find((item) => item.ghl_id === selectedGhlAccount);
    //     if (!matchingItem) {
    //       updatedClientCreatedData.push({
    //         ghl_id: selectedGhlAccount,
    //         [updateType]: true,
    //       });
    //     }

    //     return {
    //       ...prevData,
    //       otherEvents: updatedClientCreatedData,
    //     };
    //   });
    // }
  };

  const handleManageButton = (keyToUpdate) => {
    const updatedServicesSelected = { ...manageModal };
    updatedServicesSelected[keyToUpdate] = !updatedServicesSelected[keyToUpdate];
    setManageModal(updatedServicesSelected);
  };

  return (
    <div>
      {manageModal.manageClass && (
        <ServicesMtClassCalendar
          open={manageModal.manageClass}
          setOpen={(bool) =>
            setManageModal((prevState) => ({
              ...prevState,
              manageClass: bool,
            }))
          }
          selectedGhlAccount={selectedGhlAccount}
          connectionData={data}
          setConnectionData={(data) => setData(data)}
        />
      )}
      {manageModal.clientSale && (
        <ServicesMtClientSale
          open={manageModal.clientSale}
          setOpen={(bool) =>
            setManageModal((prevState) => ({
              ...prevState,
              clientSale: bool,
            }))
          }
          selectedGhlAccount={selectedGhlAccount}
          connectionData={data}
          setConnectionData={(data) => setData(data)}
        />
      )}
      {manageModal.clientCreated && (
        <ServicesMtClientCreated
          open={manageModal.clientCreated}
          setOpen={(bool) =>
            setManageModal((prevState) => ({
              ...prevState,
              clientCreated: bool,
            }))
          }
          selectedGhlAccount={selectedGhlAccount}
          connectionData={data}
          setConnectionData={(data) => setData(data)}
        />
      )}
      {manageModal.membershipAdd && (
        <ServicesMtContractCreated
          open={manageModal.membershipAdd}
          setOpen={(bool) =>
            setManageModal((prevState) => ({
              ...prevState,
              membershipAdd: bool,
            }))
          }
          selectedGhlAccount={selectedGhlAccount}
          connectionData={data}
          setConnectionData={(data) => setData(data)}
        />
      )}
      {manageModal.membershipCancel && (
        <ServicesMtContractUpdate
          open={manageModal.membershipCancel}
          setOpen={(bool) =>
            setManageModal((prevState) => ({
              ...prevState,
              membershipCancel: bool,
            }))
          }
          selectedGhlAccount={selectedGhlAccount}
          connectionData={data}
          setConnectionData={(data) => setData(data)}
        />
      )}
      {manageModal.membershipDeclined && (
        <ServicesMtMembershipCancel
          open={manageModal.membershipDeclined}
          setOpen={(bool) =>
            setManageModal((prevState) => ({
              ...prevState,
              membershipDeclined: bool,
            }))
          }
          selectedGhlAccount={selectedGhlAccount}
          connectionData={data}
          setConnectionData={(data) => setData(data)}
        />
      )}
      {manageModal.clientUpdate && (
        <ServicesMtClientUpdate
          open={manageModal.clientUpdate}
          setOpen={(bool) =>
            setManageModal((prevState) => ({
              ...prevState,
              clientUpdate: bool,
            }))
          }
          selectedGhlAccount={selectedGhlAccount}
          connectionData={data}
          setConnectionData={(data) => setData(data)}
        />
      )}
      <div className="w-full mx-auto flex justify-between place-items-center">
        <Typography.Text>Click on service name to enable / disable</Typography.Text>
        <Button
          onClick={() => {
            setData((prevData) => ({ ...prevData, classCalendar: [] }));
            setData((prevData) => ({ ...prevData, contactCreationEvent: [] }));
            setData((prevData) => ({ ...prevData, membershipEvent: [] }));
            setData((prevData) => ({ ...prevData, clientSaleEvent: [] }));
            //   handleServicesSelectedReset();
          }}
        >
          Reset connection selections
        </Button>
      </div>
      <Divider className="my-2" />

      <div className="mb-2">
        <div className="grid 2xl:grid-cols-2 items-center gap-2 grid-cols-1">
          <div>
            <Typography.Text>GHL account: </Typography.Text>
            <Select placeholder="Select an account" className="ml-2 lg:max-w-xs xl:w-52" onChange={handleSelectGhlAccount} value={selectedGhlAccount}>
              {ghlAvailAccounts.length !== 0 &&
                ghlAvailAccounts.map((data, index) => {
                  return (
                    <Select.Option key={index} value={data.id}>
                      {data.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </div>
          {/* <div>
            <Typography.Text>MT Location: </Typography.Text>
            <Select placeholder="Select a location" className="ml-2 lg:max-w-xs xl:w-52" onChange={(e) => setSelectedMtLocation(e)} value={selectedMtLocation}>
              {mtLocations.length !== 0 &&
                mtLocations.map((data) => {
                  return (
                    <Select.Option key={data?.id} value={data?.id}>
                      {data?.attributes?.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </div> */}
        </div>
      </div>

      {selectedGhlAccount && (
        <div className="grid w-full grid-cols-1 lg:grid-cols-2 gap-2">
          <Search
            defaultValue={"Class connection"}
            readOnly
            enterButton={"Manage"}
            onSearch={() => {
              handleManageButton("manageClass");
            }}
            // className={data.classCalendarActive ? "bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            className={"bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            onClick={() => {
              handleSelectServiceButton("classCalendar");
            }}
          />
          <Search
            defaultValue={"Client sale"}
            readOnly
            enterButton={"Manage"}
            onSearch={() => {
              handleManageButton("clientSale");
            }}
            // className={data.appointmentCalendarActive ? "bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            className={"bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            onClick={() => {
              handleSelectServiceButton("appointmentCalendar");
            }}
          />
          <Search
            defaultValue={"Client creation"}
            readOnly
            enterButton={"Manage"}
            onSearch={() => {
              handleManageButton("clientCreated");
            }}
            // className={data.appointmentCalendarActive ? "bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            className={"bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            onClick={() => {
              handleSelectServiceButton("appointmentCalendar");
            }}
          />
          <Search
            defaultValue={"Client update"}
            readOnly
            enterButton={"Manage"}
            onSearch={() => {
              handleManageButton("clientUpdate");
            }}
            // className={data.appointmentCalendarActive ? "bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            className={"bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            onClick={() => {
              handleSelectServiceButton("appointmentCalendar");
            }}
          />
          <Search
            defaultValue={"Membership purchase"}
            readOnly
            enterButton={"Manage"}
            onSearch={() => {
              handleManageButton("membershipAdd");
            }}
            // className={data.appointmentCalendarActive ? "bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            className={"bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            onClick={() => {
              handleSelectServiceButton("appointmentCalendar");
            }}
          />
          <Search
            defaultValue={"Membership cancellation"}
            readOnly
            enterButton={"Manage"}
            onSearch={() => {
              handleManageButton("membershipCancel");
            }}
            // className={data.appointmentCalendarActive ? "bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            className={"bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            onClick={() => {
              handleSelectServiceButton("appointmentCalendar");
            }}
          />
          <Search
            defaultValue={"Membership freeze"}
            readOnly
            enterButton={"Manage"}
            onSearch={() => {
              handleManageButton("membershipDeclined");
            }}
            // className={data.appointmentCalendarActive ? "bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input" : "bg-gray-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            className={"bg-blue-500 rounded-md max-w-[17rem] mx-auto custom-search-input"}
            onClick={() => {
              handleSelectServiceButton("appointmentCalendar");
            }}
          />
        </div>
      )}
    </div>
  );
};

export default LinkMtServicesConnection;
