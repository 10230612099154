import { Alert, Button, Spinner, Typography } from "@material-tailwind/react";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { deactivateMindbodyWebhook, generateMBWebhook, getActiveWebhook } from "../../../helpers/WebhookApi";
import { generateAppUserWebhook, getActiveCreateUserWebhook } from "../../../helpers/GhlApi";
import AuthContext from "../../../contexts/AuthContextProvider";
import { API_URL } from "../../../helpers/AppApiUrls";

const Webhook = () => {
  const { auth } = useContext(AuthContext);
  const isAdmin = auth?.userType?.includes("admin");
  const [createUserWebhooks, setCreateUserWebhooks] = useState([]);
  const [webhookID, setWebhookID] = useState(null);
  const [checkMinimumAccountsFetched, setCheckMinimumAccountsFetched] = useState(false);

  const getActiveWebhookFunc = () => {
    let toastBox = toast;
    const getActiveWebhookPromise = getActiveWebhook();
    getActiveWebhookPromise
      .then(
        (data) => {
          if (data.webhook_id) setWebhookID(data.webhook_id);
          setCheckMinimumAccountsFetched(true);
        },
        (msg) => {
          setCheckMinimumAccountsFetched(true);
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  const getActiveCreateUserWebhookFunc = () => {
    let toastBox = toast;
    const getActiveCreateUserWebhookPromise = getActiveCreateUserWebhook();
    getActiveCreateUserWebhookPromise
      .then(
        (data) => {
          console.log(data);
          if (data) setCreateUserWebhooks(data.data);
          setCheckMinimumAccountsFetched(true);
        },
        (msg) => {
          setCheckMinimumAccountsFetched(true);
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  const handleDeactivateWebhook = () => {
    let toastBox = toast;
    toast.loading(`loading...`, {
      id: toastBox,
    });
    const deactivateMindbodyWebhookPromise = deactivateMindbodyWebhook();
    deactivateMindbodyWebhookPromise
      .then(
        (data) => {
          toast.success(`Deactivated successfully`, {
            id: toastBox,
          });
          window.location.reload();
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  const handleGenerateWebhook = () => {
    let toastBox = toast;
    toast.loading(`loading...`, {
      id: toastBox,
    });
    const generateMBWebhookPromise = generateMBWebhook();
    generateMBWebhookPromise
      .then(
        (data) => {
          toast.success(`Generated successfully`, {
            id: toastBox,
          });
          window.location.reload();
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  const handleGenerateAppUserWebhook = (type) => {
    let toastBox = toast;
    toast.loading(`loading...`, {
      id: toastBox,
    });
    const generateAppUserWebhookPromise = generateAppUserWebhook(type);
    generateAppUserWebhookPromise
      .then(
        (data) => {
          toast.success(`Generated successfully`, {
            id: toastBox,
          });
          window.location.reload();
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  useEffect(() => {
    getActiveWebhookFunc();
    getActiveCreateUserWebhookFunc();
  }, []);

  return (
    <>
      {checkMinimumAccountsFetched ? (
        <div className="md:w-full w-11/12">
          <div className="w-full sm:pl-14 pt-10 sm:text-start mx-auto text-center">
            <h1 className="text-2xl">Manage Webhooks</h1>
          </div>
          <div className="w-11/12 mt-2.5 mx-auto justify-center">
            <hr />
          </div>
          <div className="w-11/12 mx-auto">
            <div className="bg-gray-50 p-4 pt-0 mt-4 w-full rounded-md row-span-2">
              <div className="w-full mx-auto justify-center">
                <Typography color="blue" className="font-normal text-lg leading-none opacity-70 w-full text-start p-5 pl-0 ">
                  Manage your Mindbody webhook
                </Typography>
                <hr className="border-gray-300" />
              </div>
              <div className="pt-5">
                {!webhookID ? (
                  <Button type="button" color="green" variant="gradient" onClick={handleGenerateWebhook}>
                    Generate Webhook
                  </Button>
                ) : (
                  <Button type="button" color="red" variant="gradient" onClick={handleDeactivateWebhook}>
                    Delete Webhook
                  </Button>
                )}
              </div>
              {webhookID ? (
                <div className="pt-5 flex flex-wrap">
                  <div>
                    <Typography color="black" className="font-normal text-lg leading-none opacity-70 w-full text-start pt-3">
                      Webhook Url:
                    </Typography>
                  </div>
                  <div className="w-max ml-4">
                    <Typography color="blue-gray" className="font-normal text-lg leading-none opacity-70 w-full text-start pt-3">
                      {API_URL === "https://webhook.staging.synx.ai" ? "https://www.webhook.staging.synx.ai/mindbody/webhook/working?id={webhookID}&flow=direct" : "https://www.webhook.synx.ai/mindbody/webhook/working?id={webhookID}&flow=direct"}
                    </Typography>
                  </div>
                </div>
              ) : (
                <Alert color="red" variant="outlined" className="w-11/12 mx-auto mt-4">
                  <span>⚠️ Please generate a webhook to enable mindbody webhook features for users.</span>
                </Alert>
              )}
            </div>
          </div>
          <div className="w-11/12 mx-auto ">
            <div className="bg-gray-50 p-4 pt-0 mt-4 w-full rounded-md row-span-2">
              <div className="w-full mx-auto justify-center">
                <Typography color="blue" className="font-normal text-lg leading-none opacity-70 w-full text-start p-5 pl-0 ">
                  Manage your auto create user webhook
                </Typography>
                <hr className="border-gray-300" />
              </div>
              <div className="pt-5">
                <Button type="button" color="green" variant="gradient" onClick={() => handleGenerateAppUserWebhook("MB")}>
                  Re-generate Webhook (For Mindbody)
                </Button>
              </div>
              {createUserWebhooks?.length !== 0 && createUserWebhooks?.filter((element) => element.connectedWith === "MB")?.length !== 0 ? (
                <div className="pt-5 flex flex-wrap">
                  <div>
                    <Typography color="black" className="font-normal text-lg leading-none opacity-70 w-full text-start pt-3">
                      Mindbody Webhook Url:
                    </Typography>
                  </div>
                  <div className="w-max ml-4">
                    <Typography color="blue-gray" className="font-normal text-lg leading-none opacity-70 w-full text-start pt-3">
                      {API_URL === "https://webhook.staging.synx.ai"
                        ? `https://www.webhook.staging.synx.ai/gohighlevel/appuser/webhook/create?id=
                      ${createUserWebhooks?.filter((element) => element.connectedWith === "MB")[0].secret_key}`
                        : `https://www.webhook.synx.ai/gohighlevel/appuser/webhook/create?id=
                      ${createUserWebhooks?.filter((element) => element.connectedWith === "MB")[0].secret_key}`}
                    </Typography>
                  </div>
                </div>
              ) : (
                <Alert color="red" variant="outlined" className="w-11/12 mx-auto mt-4">
                  <span>⚠️ No active webhook present for mindbody</span>
                </Alert>
              )}
              <div className="pt-5">
                <Button type="button" color="green" variant="gradient" onClick={() => handleGenerateAppUserWebhook("MT")}>
                  Re-generate Webhook (For MarianaTek)
                </Button>
              </div>
              {createUserWebhooks?.length !== 0 && createUserWebhooks?.filter((element) => element.connectedWith === "MT")?.length !== 0 ? (
                <div className="pt-5 flex flex-wrap">
                  <div>
                    <Typography color="black" className="font-normal text-lg leading-none opacity-70 w-full text-start pt-3">
                      MarianaTek Webhook Url:
                    </Typography>
                  </div>
                  <div className="w-max ml-4">
                    <Typography color="blue-gray" className="font-normal text-lg leading-none opacity-70 w-full text-start pt-3">
                      {API_URL === "https://webhook.staging.synx.ai"
                        ? `https://www.webhook.staging.synx.ai/gohighlevel/appuser/webhook/create?id=
                      ${createUserWebhooks?.filter((element) => element.connectedWith === "MT")[0].secret_key}`
                        : `https://www.webhook.synx.ai/gohighlevel/appuser/webhook/create?id=
                      ${createUserWebhooks?.filter((element) => element.connectedWith === "MT")[0].secret_key}`}
                    </Typography>
                  </div>
                </div>
              ) : (
                <Alert color="red" variant="outlined" className="w-11/12 mx-auto mt-4">
                  <span>⚠️ No active webhook present for marianatek</span>
                </Alert>
              )}
            </div>
          </div>
          <div className="w-11/12 mx-auto ">
            <div className="bg-gray-50 p-4 pt-0 mt-4 w-full rounded-md row-span-2">
              <div className="w-full mx-auto justify-center">
                <Typography color="blue" className="font-normal text-lg leading-none opacity-70 w-full text-start p-5 pl-0 ">
                  Manage your auto create user webhook (Force active/override)
                </Typography>
                <hr className="border-gray-300" />
              </div>

              <div className="pt-5 flex flex-wrap">
                <div>
                  <Typography color="black" className="font-normal text-lg leading-none opacity-70 w-full text-start pt-3">
                    Webhook Url:
                  </Typography>
                </div>
                <div className="w-max ml-4">
                  <Typography color="blue-gray" className="font-normal text-lg leading-none opacity-70 w-full text-start pt-3">
                    {API_URL === "https://webhook.staging.synx.ai" ? "https://www.webhook.staging.synx.ai/gohighlevel/appuser/webhook/create/override" : "https://www.webhook.synx.ai/gohighlevel/appuser/webhook/create/override"}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex place-items-center w-full h-full m-auto justify-center">
          <Spinner className="h-12 w-12" />
        </div>
      )}
    </>
  );
};

export default Webhook;
