import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Line } from "react-chartjs-2";
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const LineChart = ({ graphData, setGraphDataPointSelected }) => {
  const periodCounts = {};
  // graphData.forEach((item) => {
  //   const date = new Date(item.updated_at);
  //   const key = `${item.status === "finished" ? "success" : "error"}_${date.toISOString().split("T")[0]}`;

  //   if (!periodCounts[key]) {
  //     periodCounts[key] = 1;
  //   } else {
  //     periodCounts[key]++;
  //   }
  // });

  // let data = {
  //   labels: [],
  //   datasets: [
  //     {
  //       label: "Total Successful",
  //       data: [],
  //       borderColor: "rgb(255, 225, 132)",
  //       backgroundColor: "rgba(255, 225, 132, 0.5)",
  //     },
  //     {
  //       label: "Total Errors",
  //       data: [],
  //       borderColor: "rgb(255, 99, 132)",
  //       backgroundColor: "rgba(255, 99, 132, 0.5)",
  //     },
  //   ],
  // };

  // if (graphData?.length !== 0) {
  //   graphData = graphData.sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at));

  //   const startDate = new Date(graphData[0].updated_at); // Use updated_at property
  //   const endDate = new Date(graphData[graphData.length - 1].updated_at); // Use updated_at property
  //   const timeRangeInDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));

  //   if (timeRangeInDays > 31) {
  //     const uniquePeriods = [...new Set(Object.keys(periodCounts).map((key) => key.split("_")[1]))].sort();
  //     const datasets = [
  //       {
  //         label: "Total Successful",
  //         data: uniquePeriods.map((period) => periodCounts[`success_${period}`] || 0),
  //         borderColor: "rgb(255, 225, 132)",
  //         backgroundColor: "rgba(255, 225, 132, 0.5)",
  //       },
  //       {
  //         label: "Total Errors",
  //         data: uniquePeriods.map((period) => periodCounts[`error_${period}`] || 0),
  //         borderColor: "rgb(255, 99, 132)",
  //         backgroundColor: "rgba(255, 99, 132, 0.5)",
  //       },
  //     ];
  //     data = {
  //       labels: timeRangeInDays <= 32 ? uniquePeriods.map((date) => new Date(date).toLocaleDateString()) : [...new Set(uniquePeriods.map((date) => new Date(`${date}-01`).toLocaleString("en-us", { month: "long" })))],
  //       datasets,
  //     };
  //   } else {
  //     const uniquePeriods = [...new Set(Object.keys(periodCounts).map((key) => key.split("_")[1]))].sort(); // Use the same logic for uniquePeriods
  //     const datasets = [
  //       {
  //         label: "Total Successful",
  //         data: uniquePeriods.map((period) => {
  //           let count = 0;
  //           for (let day = 1; day <= 31; day++) {
  //             const dayString = day < 10 ? `0${day}` : `${day}`;
  //             count = count + (periodCounts[`success_${period}-${dayString}`] || 0); // Use the correct property accessor
  //           }
  //           return count;
  //         }),
  //         borderColor: "rgb(255, 225, 132)",
  //         backgroundColor: "rgba(255, 225, 132, 0.5)",
  //       },
  //       {
  //         label: "Total Errors",
  //         data: uniquePeriods.map((period) => {
  //           let count = 0;
  //           for (let day = 1; day <= 31; day++) {
  //             const dayString = day < 10 ? `0${day}` : `${day}`;
  //             count = count + (periodCounts[`error_${period}-${dayString}`] || 0); // Use the correct property accessor
  //           }
  //           return count;
  //         }),
  //         borderColor: "rgb(255, 99, 132)",
  //         backgroundColor: "rgba(255, 99, 132, 0.5)",
  //       },
  //     ];
  //     data = {
  //       labels: timeRangeInDays <= 32 ? uniquePeriods.map((date) => new Date(date).toLocaleDateString()) : [...new Set(uniquePeriods.map((date) => new Date(`${date}-01`).toLocaleString("en-us", { month: "long" })))],
  //       datasets,
  //     };
  //   }
  // }
  graphData = graphData.sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at));
  const startDateSort = new Date(graphData[0]?.updated_at);
  const endDateSort = new Date(graphData[graphData.length - 1]?.updated_at);
  const timeRangeInDaysSort = Math.ceil((endDateSort - startDateSort) / (1000 * 60 * 60 * 24));
  graphData.forEach((item) => {
    const date = new Date(item.updated_at);
    if (timeRangeInDaysSort <= 31) {
      const key = `${item.status === "finished" || item.status === "skipped" ? "success" : "error"}_${date.toISOString().split("T")[0]}`;
      if (!periodCounts[key]) {
        periodCounts[key] = 1;
      } else {
        periodCounts[key]++;
      }
    } else {
      const [yearMonth, rest] = date.toISOString().split("T")[0].split("-");
      const key = `${item.status === "finished" || item.status === "skipped" ? "success" : "error"}_${yearMonth}-${rest}`;
      if (!periodCounts[key]) {
        periodCounts[key] = 1;
      } else {
        periodCounts[key]++;
      }
    }
  });
  const uniquePeriods = [...new Set(Object.keys(periodCounts).map((key) => key.split("_")[1]))].sort();
  const startDate = new Date(uniquePeriods[0]);
  const endDate = new Date(uniquePeriods[uniquePeriods.length - 1]);
  const timeRangeInDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
  let datasets = [
    {
      label: "Total Successful",
      data: uniquePeriods.map((period) => periodCounts[`success_${period}`] || 0),
      borderColor: "rgb(255, 225, 132)",
      backgroundColor: "rgba(255, 225, 132, 0.5)",
    },
    {
      label: "Total Errors",
      data: uniquePeriods.map((period) => periodCounts[`error_${period}`] || 0),
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
  ];

  if (timeRangeInDays > 31) {
    datasets = [
      {
        label: "Total Successful",
        data: uniquePeriods.map((period) => periodCounts[`success_${period}`] || 0),
        borderColor: "rgb(255, 225, 132)",
        backgroundColor: "rgba(255, 225, 132, 0.5)",
      },
      {
        label: "Total Errors",
        data: uniquePeriods.map((period) => periodCounts[`error_${period}`] || 0),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ];
  }

  const data = {
    labels: timeRangeInDays <= 32 ? uniquePeriods.map((date) => new Date(date).toLocaleDateString()) : [...new Set(uniquePeriods.map((date) => new Date(date).toLocaleString("en-us", { month: "long" })))],
    datasets,
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const clickedIndex = elements[0].index;
        const clickedDate = data.labels[clickedIndex];
        // Filter the original dataArray to get the data for the clicked date
        const containsAlphabets = /[a-zA-Z]/.test(clickedDate);

        if (containsAlphabets) {
          // Filter the original dataArray to get the data for the clicked month
          // Filter the original dataArray to get the data for the clicked month
          const clickedData = graphData.filter((item) => {
            const monthName = new Date(item.updated_at).toLocaleString("en-us", { month: "long" });
            return monthName === clickedDate;
          });

          // Sort the clickedData array by updated_at datetime
          const sortedClickedData = clickedData.slice().sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at));

          // Get the first and last items
          const firstData = sortedClickedData[0];
          const lastData = sortedClickedData[sortedClickedData.length - 1];

          const firstDate = firstData?.updated_at;
          let lastDate = lastData?.updated_at;

          // Increase lastDate by 1 day and set time to 00:00:00
          if (lastDate) {
            const nextDay = new Date(lastDate);
            nextDay.setDate(nextDay.getDate() + 2);
            nextDay.setHours(0, 0, 0, 0);
            lastDate = nextDay.toISOString().slice(0, 19).replace("T", " ");
          }

          // Convert ISO string to a Date object for logging
          const dateObject = new Date(firstDate);
          const dateObject1 = new Date(lastDate);

          // Format the Date objects as a MySQL-compatible datetime string for logging
          const mysqlDatetimeString = dateObject.toISOString().slice(0, 19).replace("T", " ");
          const mysqlDatetimeString1 = dateObject1.toISOString().slice(0, 19).replace("T", " ");

          // Set the state with the adjusted dates
          setGraphDataPointSelected([mysqlDatetimeString, mysqlDatetimeString1]);

          // Rest of the code...
        } else {
          // Handle specific date label
          const clickedData = graphData.filter((item) => new Date(item.updated_at).toLocaleDateString() === clickedDate);
          if (clickedData?.length !== 0) {
            // Sort the clickedData array by updated_at datetime
            const sortedClickedData = clickedData.slice().sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at));

            // Get the first and last items
            const firstData = sortedClickedData[0];
            const lastData = sortedClickedData[sortedClickedData.length - 1];

            const firstDate = firstData?.updated_at;
            let lastDate = lastData?.updated_at;

            // Increase lastDate by 1 day and set time to 00:00:00
            if (lastDate) {
              const nextDay = new Date(lastDate);
              nextDay.setDate(nextDay.getDate() + 2);
              nextDay.setHours(0, 0, 0, 0);
              lastDate = nextDay.toISOString().slice(0, 19).replace("T", " ");
            }

            // Convert ISO string to a Date object for logging
            const dateObject = new Date(firstDate);
            const dateObject1 = new Date(lastDate);

            // Format the Date objects as a MySQL-compatible datetime string for logging
            console.log(graphData);
            const mysqlDatetimeString = dateObject?.toISOString().slice(0, 19).replace("T", " ");
            const mysqlDatetimeString1 = dateObject1?.toISOString().slice(0, 19).replace("T", " ");

            // Set the state with the adjusted dates
            setGraphDataPointSelected([mysqlDatetimeString, mysqlDatetimeString1]);
          }

          // Rest of the code...
        }
      }
    },
  };

  return <Line options={options} data={data} />;
};

export default LineChart;
